export type LanguageState = {
  selectedLanguage: string;
};

const languageStateKey = 'ilt-language';
export const getLanguageState = (): LanguageState | undefined => {
  const state = localStorage?.getItem(languageStateKey);
  if (!state) {
    return undefined;
  }

  return JSON.parse(state);
};

export const persistSelectedLanguage = (selectedLanguage: string) => {
  const state = getLanguageState() ?? {};
  const newState = {
    ...state,
    selectedLanguage,
  };

  localStorage.setItem(languageStateKey, JSON.stringify(newState));
};
