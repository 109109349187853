import config from '@/config/ConfigProvider';

const apiUrl = (url) => `${config.value('apiUrl')}/${url}`;
const authUrl = (url) => `${config.value('authUrl')}/${url}`;

const controlPanelUrl = (url) => `${config.value('controlPanelUrl')}/${url}`;
const libraryUrl = (url) => `${config.value('apiLibraryUrl')}/${url}`;

const exerciseUrl = (url) => `${config.value('exerciseUrl')}/${url}`;
const submissionUrl = (url) => `${config.value('submissionUrl')}/${url}`;
const exerciseTrackerUrl = (url) => `${config.value('exerciseTrackerUrl')}/${url}`;
const curriculumUrl = (url) => `${config.value('curriculumUrl')}/${url}`;

export { apiUrl, authUrl, controlPanelUrl, libraryUrl, exerciseUrl, submissionUrl, exerciseTrackerUrl, curriculumUrl };
