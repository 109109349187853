<script setup lang="ts">
import manabuaPrimaryIcon from '@/assets/images/manabua-m-blue.svg';
import begreppaPrimaryIcon from '@/assets/images/begreppa-b.svg';
import navVideoIcon from '@/assets/images/nav-video-icon.svg';
import navGuideIcon from '@/assets/images/nav-guide-icon.svg';
import navLibraryIcon from '@/assets/images/nav-library-icon.svg';
import subjectsIcon from '@/assets/images/icon-subjects.svg';

import config from '@/config/ConfigProvider';

const serviceName = config.value('serviceName') as string;

const icons = {
  primaryIcon: serviceName === 'manabua' ? manabuaPrimaryIcon : begreppaPrimaryIcon,
  videoIcon: navVideoIcon,
  guideIcon: navGuideIcon,
  libraryIcon: navLibraryIcon,
  subjectsIcon,
};

defineProps<{
  icon: keyof typeof icons;
  tag: string;
}>();
</script>

<template>
  <v-img :src="icons[icon]" alt="icon" />
</template>

<style scoped></style>
