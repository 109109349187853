import { computed, ref } from 'vue';
import type { BookV1, Content } from '@ilteducation/content-registry-types';
import { defineStore } from 'pinia';
import { getById, getByLegacyId, getByIds, getContentDownloadUrl, getCoverUrl } from './api';
import persistenceOptions from './content-store-persistence'; // eslint-disable-line import/no-cycle

export type State = {
  books: Map<string, BookV1>;
  isFetching: boolean;
};

export type StateTree = {
  state: State;
};

const useBooks = defineStore(
  'books',
  () => {
    const state = ref<State>({
      books: new Map(),
      isFetching: false,
    });

    const getBooks = computed(() => state.value.books);
    const isFetching = computed(() => state.value.isFetching);

    const getBookById = async (bookId: string): Promise<BookV1 | undefined> => {
      if (state.value.books.has(bookId)) {
        return state.value.books.get(bookId);
      }

      state.value.isFetching = true; // should have individual flag per content?
      await getById(bookId).then((book) => state.value.books.set(book.id, book));
      state.value.isFetching = false;

      return state.value.books.get(bookId);
    };

    const getBookByLegacyId = async (bookId: string, realm: string): Promise<Content | undefined> => {
      state.value.isFetching = true;
      return getByLegacyId(bookId, realm).then((book) => {
        state.value.books.set(book.id, book);
        state.value.isFetching = false;
        return book;
      });
    };

    // TODO: this should work but the api call dont exist???.
    const getBooksByIds = async (bookIds: string[]) => {
      state.value.isFetching = true;
      // console.debug('Fetching books by ids', bookIds);
      const idsNotFound = bookIds.filter((id) => ![...state.value.books.keys()].includes(id));
      // console.debug('Ids not found', idsNotFound);
      // console.debug(state.value.books);
      if (idsNotFound.length !== 0) {
        await getByIds(idsNotFound).then((entities) =>
          // console.debug('Fetched books by ids', entities);
          entities.forEach((entity) => state.value.books.set(entity.id, entity)),
        );
      }
      state.value.isFetching = false;
      return [...state.value.books.values()].filter((book) => bookIds.includes(book.id));
    };

    const addBooks = (entities: Content[]) => {
      entities.forEach((entity) => state.value.books.set(entity.id, entity as BookV1));
    };

    return {
      state,
      books: getBooks,
      isFetching,
      getById: getBookById,
      getByLegacyId: getBookByLegacyId,
      getByIds: getBooksByIds,
      getCoverUrl,
      getContentDownloadUrl,
      addBooks,
    };
  },
  {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    persist: persistenceOptions,
  },
);

export default useBooks;
