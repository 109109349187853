import { Capacitor } from '@capacitor/core';
import type { BookV1 } from '@ilteducation/content-registry-types';
import { StateTree } from './use-books'; // eslint-disable-line import/no-cycle

type Serializer = {
  deserialize: (data: string) => StateTree;
  serialize: ({ state }: StateTree) => string;
};

type PiniaPersistOptions = {
  serializer: Serializer;
};

// eslint-disable-next-line import/no-mutable-exports
let options: PiniaPersistOptions | undefined;

if (Capacitor.isNativePlatform()) {
  options = {
    serializer: {
      deserialize: (data: string): StateTree => {
        const { serializedBooks, isFetching } = JSON.parse(data);
        const books: Map<string, BookV1> = new Map(JSON.parse(serializedBooks));
        return { state: { books, isFetching } };
      },
      serialize: ({ state }) => {
        const { books, isFetching } = state;
        const arrayOfBookEntries = Array.from(books.entries());
        const serializedBooks = JSON.stringify(arrayOfBookEntries);
        return JSON.stringify({ isFetching, serializedBooks });
      },
    },
  };
}

export default options;
