import { getCurrentInstance } from 'vue';

/**
 * Composable to expose the vuex store
 */
const useStore = () => {
  const vm = getCurrentInstance()!.proxy; // as seen in https://github.com/intlify/vue-i18n-composable/blob/v2.0.0/src/index.ts

  // @ts-ignore
  return vm.$store;
};

export default useStore;
