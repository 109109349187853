import { Composer, useI18n } from 'vue-i18n';

const createLanguageReference = (o: any, source: any, path: string) => {
  Object.keys(source).forEach((child) => {
    if (typeof source[child] === 'object') {
      const n = {};
      createLanguageReference(n, source[child], `${path}${child}.`);
      o[child] = n; // eslint-disable-line
    } else {
      o[child] = `${path}${child}`; // eslint-disable-line
    }
  });
};

export type UseLocale<L> = () => {
  // @ts-ignore
  t: Composer<L>['t']; // FIX ME. or start using VueI18nTranslation
  // @ts-ignore
  tm: Composer<L>['tm']; // FIX ME. or start using VueI18nTranslationChoice
  l: L;
};

const createUseLocale = <L>(defaultLanguage: L): UseLocale<L> => {
  const l = {} as L;

  createLanguageReference(l, defaultLanguage, '');

  return () => {
    const { t, tm } = useI18n<{ message: L }>();

    return {
      // @ts-ignore
      t: t as Composer<L>['t'], // fixme: forced cast until we figure it out...
      tm,
      l,
    };
  };
};

export default createUseLocale;
