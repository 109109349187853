import { LanguageDefinition } from '@ilteducation/localisation-vue3';

const languages: LanguageDefinition[] = [
  {
    iso: 'sv-SE',
    short: 'sv',
    name: 'Swedish',
  },
  {
    iso: 'en-GB',
    short: 'en',
    name: 'English',
  },
  {
    iso: 'nb-NO',
    short: 'nb',
    name: 'Norwegian',
  },
  {
    iso: 'nn-NO',
    short: 'nn',
    name: 'Norwegian Nynorsk',
  },
  {
    iso: 'fi-FI',
    short: 'fi',
    name: 'Finnish',
  },
];

export default languages;
