import { computed, ComputedRef } from 'vue';
import { I18n } from 'vue-i18n';
import loadLanguage from './language-loader';
import { persistSelectedLanguage } from './store';
import { LanguageDefinition } from './types';

export type UseLocalisation = () => {
  supportedLanguages: LanguageDefinition[];
  language: ComputedRef<string>;
  isLanguageSupported: (language: string) => boolean;
  changeLanguage: (language: string) => Promise<void>;
  includeAdditionalProject: (projectName: string, isoLanguage?: boolean) => Promise<void>;
  getLanguageNameWithIso: (iso: string) => string;
};

export type AdditionalProject = {
  name: string;
  isoLanguage?: boolean;
};

const createUseLocalisation = (
  appName: string,
  i18n: I18n<any, any, any, string, false>,
  supportedLanguages: LanguageDefinition[],
): UseLocalisation => {
  const projects: AdditionalProject[] = [];
  const isLanguageSupported = (language: string): boolean => supportedLanguages.some((l) => l.iso === language);

  const languageToDownload = (language: string, isoLanguage = false): string =>
    // to pull project translations from lokalise where language iso codes may vary
    // for example, for app ui translations it will return 'sv-SE' and for keywords – 'sv'
    isoLanguage ? language.split('-')[0] : language;

  const language = computed(() => i18n.global.locale.value); // wrap it as read-only

  const loadAdditionalProject = (project: AdditionalProject): Promise<void> =>
    loadLanguage(project.name, languageToDownload(language.value, project.isoLanguage)).then((msgs) => {
      i18n.global.setLocaleMessage(language.value, { ...i18n.global.getLocaleMessage(language.value), ...msgs });
    });
  const includeAdditionalProject = (projectName: string, isoLanguage = true): Promise<void> => {
    const project: AdditionalProject = { name: projectName, isoLanguage };
    projects.push(project);
    return loadAdditionalProject(project);
  };
  const changeLanguage = (newLanguage: string): Promise<void> => {
    if (!isLanguageSupported(newLanguage)) {
      return Promise.reject(
        new Error(
          `Language "${newLanguage}" not supported. Supported languages: ${supportedLanguages
            .map((l) => l.iso)
            .join(', ')}`,
        ),
      );
    }

    if (language.value === newLanguage) {
      return Promise.resolve();
    }

    return loadLanguage(appName, newLanguage).then((msgs) => {
      i18n.global.setLocaleMessage(newLanguage, msgs);
      // eslint-disable-next-line no-param-reassign
      i18n.global.locale.value = newLanguage;

      document.querySelector('html')!.setAttribute('lang', newLanguage);
      persistSelectedLanguage(newLanguage);
    });
  };

  const getLanguageNameWithIso = (iso: string): string => supportedLanguages.find((l) => l.iso === iso)?.name ?? iso;
  return () => ({
    supportedLanguages,
    language,
    isLanguageSupported,
    changeLanguage,
    includeAdditionalProject,
    getLanguageNameWithIso,
  });
};

export default createUseLocalisation;
