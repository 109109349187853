import { createRouter, createWebHistory, LocationQuery } from 'vue-router';
import { getActiveHowl, stopAllSounds } from '@ilteducation/audio-resources';
import { useUserContext } from '@ilteducation/ilt-auth';
import { isArray } from 'lodash';
import config from './config/ConfigProvider';
import useSubscriptions from './foundations/helpers/use-subscriptions';

// lazy loading
const EmptyRouterView = () => import('./views/EmptyRouterView.vue');
const Login = () => import('./views/Login.vue');
const LoginSSO = () => import('./views/LoginSSO.vue');
const Landing = () => import('./views/Landing.vue');
const MyPage = () => import('./views/MyPage.vue');
const UserPage = () => import('./views/UserPage.vue');
const Videos = () => import('./views/Videos.vue');
const SubjectPage = () => import('./views/SubjectPage.vue');
const Guides = () => import('./views/Guides.vue');
const SingleVideo = () => import('./views/SingleVideo.vue');
const AreaGuide = () => import('./views/AreaGuide.vue');
const PlayBook = () => import('./views/PlayBook.vue');
const Library = () => import('./views/Library.vue');
const NotFound = () => import('./views/NotFound.vue');
const NoAccess = () => import('./views/NoAccess.vue');

const basePath = config.value('basePath');

const router = createRouter({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition && to.name === 'Videos' && from.name === 'showFilm') {
      localStorage.setItem('videoScrollTop', JSON.stringify(savedPosition.top));
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
    return { left: 0, top: 0 };
  },
  history: createWebHistory(basePath || '/'),
  strict: true,
  routes: [
    {
      path: '/',
      redirect: (to) => {
        const userContext = useUserContext();
        if (userContext.isLoggedIn) {
          return { name: 'begreppaLanding' };
        }
        return { name: 'login', query: to.query };
      },
    },
    {
      path: '/noaccess',
      name: 'noaccess',
      component: NoAccess,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/login/:loginHint',
      name: 'loginSSO',
      component: LoginSSO,
      props: true,
      beforeEnter: (_to, _from, next) => {
        const userContext = useUserContext();
        if (userContext.isLoggedIn) {
          next({ name: 'begreppaLanding' });
        } else {
          next();
        }
      },
    },
    {
      path: '',
      name: 'begreppaLanding',
      component: Landing,
      meta: {
        requiresAuth: true,
      },
      redirect: {
        name: 'Videos',
      },
      children: [
        {
          path: '/videos',
          name: 'Videos',
          component: Videos,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/library',
          name: 'Library',
          component: Library,
          props: { shelf: true },
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/play-book/:id',
          name: 'PlayBook',
          component: PlayBook,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/library/:id(\\d+)',
          name: 'Book',
          component: Library,
          props: true,
        },
        {
          path: '/guides',
          name: 'Guides',
          component: Guides,
        },
        {
          path: '/videos/:subject',
          name: 'SubjectPage',
          component: SubjectPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/videos/:subject/:area',
          name: 'videos.AreaGuide',
          component: AreaGuide,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/goto/videos/:subject/:area/:film',
          meta: {
            requiresAuth: true,
          },
          redirect: (to) => ({ name: 'showFilm', query: to.query }),
        },
        {
          path: '/videos/:subject/:area/:film',
          name: 'showFilm',
          component: SingleVideo,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/begreppa/:rest+',
          redirect: (to) => ({
            path: router.resolve(isArray(to.params.rest) ? to.params.rest.join('/') : to.params.rest).href,
          }),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/my-page',
          name: 'myPage',
          component: MyPage,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/users',
      component: EmptyRouterView,
      children: [
        {
          path: ':userUid',
          name: 'users.person',
          component: UserPage,

          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (getActiveHowl()) {
    stopAllSounds();
  }

  const userContext = useUserContext();
  const subscriptions = useSubscriptions();
  if (to.meta.requiresAuth && !userContext.isLoggedIn) {
    const query: LocationQuery = {};
    if (typeof to.query.reauthenticate !== 'undefined') {
      query.reauthenticate = 'auto';
    }
    localStorage.setItem('savedRouteOnLogin', JSON.stringify(to.fullPath));
    next({ name: 'login', query });
  } else if (to.meta.requiresAuth && userContext.isLoggedIn && subscriptions.value.length === 0) {
    next({ name: 'noaccess' });
  }

  next();
});

export default router;
