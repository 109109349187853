// Standard locale used by Stripe, [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag)
const countryLocale: Record<string, string> = {
  de: 'de',
  fi: 'fi',
  fr: 'fr',
  no: 'nb',
  nl: 'nl',
  se: 'sv',
  uk: 'en-GB',
  us: 'en-US',
};

export default countryLocale;
