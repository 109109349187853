import { datadogRum } from '@datadog/browser-rum';

export const reportError = (error: Error, context?: object) => {
  datadogRum.addError(error, context);
};

export const report = (error: unknown, context?: object) => {
  datadogRum.addError(error, context);
};

export const reportAction = (name: string, context?: object) => {
  datadogRum.addAction(name, context);
};
