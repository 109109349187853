<script setup lang="ts">
import { watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import _ from 'lodash';
import { useUserContext, useLimitedSessionDuration, ImpersonateBar } from '@ilteducation/ilt-auth';
import config from '@/config/ConfigProvider';
import TopBar from '@/components/base/TopBar.vue';
import BurgerMenu from '@/components/base/BurgerMenu.vue';
import BottomNavigation from '@/components/base/BottomNavigation.vue';
import useIdentityActivation from '@/composables/use-identity-activation';
import useSnackbar from '@/composables/use-snackbar';
import useSubscriptions from './foundations/helpers/use-subscriptions';

const basePath = config.value('basePath');
const serviceName = config.value('serviceName');
const countryCode = config.value('countryCode');

document.title = _.capitalize(serviceName);
document.getElementsByTagName('title')[0].text = countryCode === 'se' ? 'Begreppa' : 'Manabua';
document.querySelector('link[rel="manifest"]')!.setAttribute('href', `${basePath}${countryCode}.manifest.json`);
document
  .querySelector('link[rel="apple-touch-icon"]')!
  .setAttribute('href', `${basePath}${countryCode === 'se' ? 'begreppa-logo.png' : 'manabua-logo.png'}`);
document.querySelectorAll('link[rel="icon"]').forEach((item) => {
  item.setAttribute('href', `${basePath}favicon-${serviceName}.ico`);
});

const userContext = useUserContext();
const { isLoggedIn } = storeToRefs(userContext);
const hasAccess = computed(() => {
  const subscriptions = useSubscriptions();
  return subscriptions.value.length > 0;
});

useIdentityActivation();

watch(isLoggedIn, async (newValue, oldValue) => {
  // Did the user log out?
  if (oldValue && !newValue) {
    window.location.href = basePath || '/';
  }
});

useLimitedSessionDuration('/login');

const { snack } = useSnackbar();
</script>

<template>
  <v-app class="manabua">
    <ImpersonateBar />
    <TopBar v-if="isLoggedIn && hasAccess" white />
    <BurgerMenu v-if="isLoggedIn && hasAccess" />
    <v-main>
      <router-view />
    </v-main>
    <BottomNavigation v-if="isLoggedIn && hasAccess && countryCode !== 'fi' && countryCode !== 'no'" />
    <v-snackbar
      v-model="snack.show"
      :color="snack.color"
      :timeout="snack.color === 'error' ? -1 : 5000"
      close-on-back
      close-on-content-click
      style="z-index: 20000"
    >
      {{ snack.message }}
      <template v-if="snack.color === 'error'" #actions>
        <v-btn variant="text" @click="snack.show = false"> Close</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style lang="scss">
@import '@/foundations/theme/styles.scss';

.v-application {
  font-family: inherit !important;
}

body {
  touch-action: pan-x pan-y;
}

html,
.v-application--wrap {
  background-color: #f0f5f8;
}

.v-application a {
  color: inherit !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-app-bar.v-app-bar--fixed {
  z-index: 25;
}

@media (max-width: 960px) {
  .v-application {
    padding-bottom: 70px;
  }
}
</style>
