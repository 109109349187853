import { ref } from 'vue';
import { defineStore } from 'pinia';
import { Content } from '@ilteducation/content-registry-types';

const useHistoryCacheStore = defineStore('history-cache', () => {
  const history = ref<Content[]>([]);

  const push = (content: Content) => {
    if (!history.value.some((cachedContent: Content) => cachedContent.id === content.id))
      history.value.unshift(content);
  };

  return {
    history,
    push,
  };
});

export default useHistoryCacheStore;
