import axios from 'axios';
import { exerciseUrl, submissionUrl, exerciseTrackerUrl } from '@/foundations/helpers/router';

export const exerciseApi = {
  getExercises(filmId) {
    return axios.get(exerciseUrl(`?resourceId=${filmId}`)).then((response) => response);
  },
  createSubmission(quiz) {
    return axios.post(submissionUrl(''), quiz).then((response) => response);
  },
  updateSubmission(submissionId, quiz) {
    return axios.patch(submissionUrl(`${submissionId}`), quiz).then((response) => response);
  },
  getSubmissions(contextId, contextType) {
    return axios
      .get(submissionUrl(`?contextId=${contextId}&contextType=${contextType}&ownership=Mine`))
      .then((response) => response.data);
  },
  getExerciseTracker(filmId) {
    return axios.get(exerciseTrackerUrl(filmId)).then((response) => response);
  },
  getExerciseTrackers() {
    return axios.get(exerciseTrackerUrl('')).then((response) => response);
  },
  getExerciseTrackersByResourceId(resourceId) {
    return axios.get(exerciseTrackerUrl(resourceId)).then((response) => response.data);
  },
};
