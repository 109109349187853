import { configHelper as ch } from '@ilteducation/config';

const configHelper = {
  getServiceName: () => {
    const service = window.location.hostname.split('.')[1];
    if (ch.isLocal() || service === undefined) {
      return 'manabua';
    }

    return service;
  },

  getFullServiceName: () => `${configHelper.getServiceName()}.${ch.getLocale()}`,

  getToastClass: (serviceName) => `${serviceName}-toast-bubble`,

  getBasedLinks: () => {
    // TODO: replace this with built links like education app.
    const countryCode = ch.getCountryCode();
    // Norway and finish sites has different urls.
    const prodDomain = countryCode !== 'se' ? `www.ilteducation.${countryCode}` : `www.inlasningstjanst.${countryCode}`;
    const stagingDomain =
      countryCode !== 'se' ? `www-dev.ilteducation.${countryCode}` : `www-dev.inlasningstjanst.${countryCode}`;
    const appProdDomain = `app.ilteducation.${countryCode}`;
    const appStagingDomain = `app-dev.ilteducation.${countryCode}`;

    const domain = ch.isProd() ? prodDomain : stagingDomain;
    const appDomain = ch.isProd() ? appProdDomain : appStagingDomain;

    return {
      mainDomain: `https://${appDomain}`,
      settings: `https://${appDomain}/settings?reauthenticate=auto`,
      profile: `https://${appDomain}/profile?reauthenticate=auto`,
      home: `https://${appDomain}/?reauthenticate=auto`,
      latestNews: `https://${appDomain}/latest-news?reauthenticate=auto`,
      support: ch.getLocale() === 'fi' ? `https://${domain}/asiakaspalvelu/` : false,
    };
  },

  getPortalUrl: () => {
    const host = ch.host();

    if (host.startsWith('local') || host.startsWith('app-dev')) {
      return 'https://portal-dev.inlasningstjanst.se';
    }

    switch (ch.environment()) {
      case 'staging':
        return 'https://portal-dev.inlasningstjanst.se';
      case 'production':
        return 'https://portal.inlasningstjanst.se';
      default:
        return 'https://portal.inlasningstjanst.se';
    }
  },
};

export default configHelper;
