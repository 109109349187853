import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { getViewCollections } from './api';
import { ViewCollectionsQuery } from './types';
import useContentCollectionsStore from './content-collections';
import useActiveIdentity from './use-active-identity';

type ViewState = {
  isLoading: boolean;
  viewCollectionQuery: ViewCollectionsQuery;
  collections: string[];
};

type State = {
  [viewCollectionId: string]: ViewState | undefined;
};

const viewCollections = defineStore('view-collections', () => {
  const viewStates = ref<State>({});
  const isLoadingByView = computed(
    () => (viewCollectionId: string) => viewStates.value[viewCollectionId]?.isLoading ?? false,
  );
  const viewHasMore = computed(
    () => (viewCollectionId: string) => viewStates.value[viewCollectionId]?.viewCollectionQuery.cursor !== null,
  );
  const cursor = computed(
    () => (viewCollectionId: string) => viewStates.value[viewCollectionId]?.viewCollectionQuery.cursor,
  );
  const collectionsByView = computed(() => (viewId: string) => {
    const { collectionById } = useContentCollectionsStore();

    return viewStates.value[viewId]?.collections.map((collectionId) => collectionById(collectionId)!) ?? [];
  });

  const identityStore = useActiveIdentity();
  const { identity } = storeToRefs(identityStore);

  watch(identity, () => {
    // reset on identity change
    viewStates.value = {};
  });

  const load = (viewId: string, viewCollectionQuery: ViewCollectionsQuery): Promise<void> => {
    const viewState = viewStates.value[viewId];
    if (viewState?.isLoading === true) {
      return Promise.resolve();
    }

    viewStates.value = {
      ...viewStates.value,
      ...{
        [viewId]: {
          isLoading: true,
          viewCollectionQuery,
          collections: viewState?.collections ?? [],
        },
      },
    };

    return getViewCollections(viewId, viewCollectionQuery, identity.value.profileId).then(({ cursor, data }) => {
      const collectionIds = data.map((collection) => collection.id);

      const { put } = useContentCollectionsStore();
      put(data);

      viewStates.value = {
        ...viewStates.value,
        ...{
          [viewId]: {
            isLoading: false,
            viewCollectionQuery: {
              ...viewStates.value[viewId]?.viewCollectionQuery,
              cursor,
            },
            collections: [...(viewState?.collections ?? []), ...collectionIds],
          },
        },
      };
    });
  };

  const next = (viewCollectionId: string) => {
    const viewState = viewStates.value[viewCollectionId];
    if (!viewState) {
      console.error(`Can't paginate view ${viewCollectionId} when it hasn't been loaded.`);
      return Promise.resolve();
    }

    return load(viewCollectionId, viewState.viewCollectionQuery);
  };

  return {
    cursor,
    collectionsByView,
    isLoadingByView,
    viewHasMore,
    load,
    next,
  };
});

export default viewCollections;
