<script setup lang="ts">
import { ref } from 'vue';
import { useLocale, useLocalisation } from '@/plugins/translation';
import languageIcon from '@/assets/images/flags/icon-language.svg';
import { useDisplay } from 'vuetify';

const emit = defineEmits(['close']);

const { supportedLanguages, language: currentLanguage, changeLanguage } = useLocalisation();
const { t, l } = useLocale();
const { xs } = useDisplay();

const showDialog = ref(true);

const handleClose = () => emit('close');
const handleChangeLanguage = (language: string) => {
  changeLanguage(language);
  setTimeout(handleClose, 800);
};

const isCurrentLanguage = (language: string) => currentLanguage.value === language;
</script>

<template>
  <v-dialog
    v-model="showDialog"
    transition="scale-transition"
    :fullscreen="xs"
    scrollable
    :max-width="xs ? 'none' : 300"
    @keydown.esc="handleClose"
    @click:outside="handleClose"
  >
    <v-card class="overflow-auto" flat>
      <v-card-title class="d-flex align-center">
        <div class="d-flex align-center">
          <v-img class="mr-1" width="24" max-height="24" max-width="24" :src="languageIcon" />
          <span>{{ t(l.film.chooseLanguage) }}</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon variant="plain" @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-list>
          <v-chip
            v-for="language in supportedLanguages"
            :key="language.iso"
            :class="{ selectedLang: isCurrentLanguage(language.iso) }"
            class="text-capitalize languageOptionChip text-body-1 mb-2"
            @click="handleChangeLanguage(language.iso)"
          >
            {{ t(`languages.${language.iso.split('-')[0]}`) }}
          </v-chip>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.selectedLang {
  background: rgb(var(--v-theme-primary));
  color: white;
}
.languageOptionChip {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 22px;
  min-height: 44px;
  line-height: 44px;
  margin-right: 0;
}
.v-dialog {
  .container {
    position: relative;
  }
}
</style>
