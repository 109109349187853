import { watchEffect } from 'vue-demi';
import useUserContext from './use-user-context';

type SessionLimitRule = {
  amr: string;
  limitMs: number;
};

const rules: SessionLimitRule[] = [
  {
    amr: 'http://ilt.cloud/auth-method/feide',
    limitMs: 8 * 60 * 60 * 1000, // (Polylino) Feide in Norway requires relogin after 8 hours.
  },
  {
    amr: 'http://ilt.cloud/auth-method/feide2',
    limitMs: 8 * 60 * 60 * 1000, // (Manabua) Feide in Norway requires relogin after 8 hours.
  },
];

let timeoutHandle: NodeJS.Timeout | undefined;

const useLimitedSessionDuration = (redirectPathPrefix: string) => {
  const userContext = useUserContext();

  const endSession = (amr: string) => {
    if (timeoutHandle === undefined) {
      // in case we have a race...
      return;
    }

    userContext.logout();

    timeoutHandle = undefined;

    const amrName = amr.substring('http://ilt.cloud/auth-method/'.length);
    window.location.pathname = `${redirectPathPrefix}/${amrName}`;
  };

  watchEffect(() => {
    if (!userContext.isLoggedIn) {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
      return;
    }

    if (timeoutHandle) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rule: SessionLimitRule | undefined = rules.find((r) => userContext.amr?.find((amr) => r.amr === amr));
    if (rule === undefined) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const deadline = rule.limitMs - (Date.now() - userContext.authTime!.getTime());
    timeoutHandle = setTimeout(() => endSession(rule.amr), Math.max(deadline, 0));
  });
};

export default useLimitedSessionDuration;
