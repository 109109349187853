import { setup } from '@ilteducation/localisation-vue3';
import devLanguage from '@/locale/dev';
import supportedLanguages from './supported-languages';

const defaultLanguageName = 'dev';

export const { i18n, useLocalisation, useLocale } = setup({
  appName: 'Manabua',
  defaultLanguageName,
  defaultLanguage: devLanguage,
  supportedLanguages,
});
