export default {
  tabs: {
    films: 'Films',
    guides: 'Guides',
    guides_short: 'Guides',
    library: 'The bookshelf',
  },
  home: 'Home',
  print: 'Print',
  show_more: 'Show more',
  showing: 'Showing',
  of: 'out of',
  book_titles: 'titles',
  to_top: 'To the top',
  button_log_out_user: 'Logout',
  label_version_number: 'Version',
  show_explanations: 'Show explanations',
  share_to_classroom: 'Share to Classroom',
  book_added_to_shelf: 'Book added to the bookshelf',
  book_no_access: 'You do not have access to this book',
  login: {
    message_welcome_to_service_manabua: 'Welcome to Manabua!',
    message_welcome_to_service_begreppa: 'Welcome to Begreppa!',
    button_log_in: 'Log in',
    message_service_description: '',
    login_error: 'Sorry, there has been an error processing your request.',
    login_processing: 'Login processing, please wait...',
    logged_in_as: 'logged in as',
  },
  no_access: {
    title: 'You do not have access to this service',
    text: 'To see your services, click here',
    button: 'Go back to Log in',
  },
  introduction: {
    title: 'What do you want to watch today?',
    text: '',
  },
  search: {
    search: 'Search',
    all_grades: 'All grades',
    all_subjects: 'All subjects',
    search_result: 'Search result:',
    no_video_search_result: 'No films found.',
    no_guide_search_result: 'No guides found.',
    all_languages: 'All languages',
  },
  subject: {
    latest_films: 'Latest films',
    explore_all: 'Explore all',
  },
  film: {
    noRelatedFilms: 'Nothing to see here.',
    noFilms: 'No films here, but we are working on it!',
    filmsInArea: 'Films within area',
    chooseLanguage: 'Choose language',
    sound: 'Sound',
    subtitles: 'Subtitles',
    skipIntro: 'Skip intro',
    skipIntros: 'Skip intros',
    off: 'Off',
    shareVideo: 'Share video',
    copiedVideoLink: 'Video link copied to clipboard!',
    immerseInStudyMaterial: 'Textbooks',
    immerseLinksComingSoon: 'Immerse links coming soon.',
    keywordsComingSoon: 'Keywords will be added soon...',
    missingFilms: 'Films missing',
    show_explanations: 'Show explanations',
    keywordsSwitchView: 'Flashcards',
    keywordsListView: 'Listview',
    easyToRead: 'Easy to read',
    subtitle: 'Subtitle',
    play_all: 'Play all',
    tabs: {
      concept: 'Keywords',
      quiz: 'Quiz',
      immerse: 'Textbooks',
      subtitles: 'Text',
      teaching: 'Teaching',
      curriculum: 'Curriculum',
      exercises: 'Exercises',
    },
    immerseTooltip: 'Links to study guides and textbook recordings.',
  },
  languages: {
    name: {
      'sv-SE': 'Svenska',
      'en-GB': 'English',
      'nb-NO': 'Bokmål',
      'nn-NO': 'Nynorsk',
      'fi-FI': 'Suomi',
    },
    sv: 'Swedish',
    en: 'English',
    'en-gb': 'English',
    ar: 'Arabic',
    fa: 'Dari',
    prs: 'Dari', // TODO: Should be able to use lokalise's key referencing feature to avoid duplicate keys but couldn't make it work
    fi: 'Finnish',
    'no-nb': 'Norwegian (Bokmål)',
    nb: 'Bokmål',
    nn: 'Nynorsk',
    'no-nn': 'Norwegian (Nynorsk)',
    pl: 'Polish',
    ru: 'Russian',
    so: 'Somali',
    th: 'Thai',
    ti: 'Tigrinya',
    uk: 'Ukrainian',
    ukr: 'Ukrainian',
  },
  quiz: {
    quiz_progress: 'Question {current}/{last}',
    final_result: '{correct}/{total} correct answers!',
    try_again: 'Try again!',
    congratulations: 'Congratulations - you got it!',
    congratulations_next_quiz: 'Congratulations! You can now take the next quiz.',
    back: 'Back',
    next: 'Next',
    show_result: 'Show my results',
    loading_quiz: 'Loading quiz',
    correcting_answers: 'Grading...',
    repeat_first_quiz: 'Take previous quiz',
    repeat_quiz: 'Redo quiz',
    next_quiz: 'Take next quiz',
    replay_film: 'Watch film again',
    go_to_immerse: 'Go to textbooks',
    your_answer: 'Your response',
    correct_answer: 'Correct response',
    locked_quiz_explanation: 'Once you have completed the first quiz, you will be able to take the next quiz.',
    unlocking_a_quiz_requires_all_successful_answers:
      'You need all the correct answers to the first quiz to move on to the next quiz.',
    feedback: {
      0: ['Too bad!', 'Whoops!', 'Oh no!'],
      1: ['You can do better!', 'Try again!', 'Once again maybe?'],
      2: ['You can do better!', 'Try again!', 'Once again maybe?'],
      3: ['Almost there', 'Not quite there', 'Try again!'],
      4: ['Great work!', 'Wow!', 'Congratulations - you got it!'],
    },
    error_saving_submission: 'There was an error saving your answers.',
  },
  menu: {
    profile: 'Profile',
    dashboard: 'ILT Home',
    latest_news: 'Latest news',
    settings: 'Settings',
    support: 'Support',
    my_page: 'My page',
    feedback_form: 'Give us feedback!',
    teacher_guide: 'Guide',
    account: 'Account',
  },
  guides: {
    title: 'Audioguides to your textbooks',
    show_more_description:
      'Our audioguides assists you in understadning your textbooks in your mother tongue. The audioguides are available in Tigrinja, Arabic, Somali och Persian/Dari. Search for you textbook below,',
    show_more_link: 'or get an overview of our selection here.',
  },
  library: {
    buy: 'Buy',
    onCD: 'on CD',
    book_title: 'title',
    price: 'Price',
    organisation: 'Organisation',
    add_and_play_book: 'Add and play book',
    my_bookshelf: 'My bookshelf',
    add_book_to_student: "Add to student's bookshelf",
    author: 'Author',
    publisher: 'Publisher',
    playtime: 'Playtime',
    link_to_book: 'Link to the book',
    listen: 'Listen',
    student_bookshelf: 'Students bookshelf',
    no_access_to_book: 'This book is not available in your subscription',
    require_secure_login: 'Requires secure authentication',
    remove_from_shelf: 'Remove from bookshelf',
    book_removed_from_shelf: 'Book removed from bookshelf',
    buy_CD: 'Buy on CD',
    your_books_in_shelf: 'My books',
    epub_explanation: 'This is an ePub book. It contains both audio and text.',
    full_text: 'Full text',
    only_for_speaking_book_rights: 'This is book is only for users with speaking book rights',
    no_books_in_shelf: 'There are no books in your bookshelf!',
    no_books_found: "Couldn't find any books",
    sorting: {
      last_added: 'Last added',
      last_read: 'Last Read',
      title: 'Title',
      author: 'Author',
    },
    textbooks_in_swedish: 'Textbooks in Swedish',
    textbooks_in_other_languages: 'Textbooks in other languages',
  },
  my_page: {
    edit_info: 'My profile',
    no_videos: 'Here you will find the films you have watched.',
    watched_films_tab: {
      tab_title: 'My watched films',
    },
    progress_tab: {
      tab_title: 'Progress',
    },
    no_data: 'No data available',
    my_students_tab: {
      tab_title: 'My Students',
      page_title: 'Selected students',
      content_title: 'All students',
      search_placeholder: 'Name',
      student: 'Student | Students',
      group: 'Group | Groups',
      organization: 'Organization | Organizations',
    },
  },
  user_page: {
    no_videos: 'Here you will find the films the student has watched.',
    watched_films_tab: {
      tab_title: 'Watched films',
    },
  },
  flash_card: {
    click_to_flip: 'Click to flip the card',
    next_slide: 'Next slide',
    prev_slide: 'Previous slide',
    card: 'Card',
  },
  kahoot: {
    tooltip1: 'KAHOOT! is an external service.',
    tooltip2: 'We recommend using KAHOOT! without logging in, in guest mode, to avoid the disclosure of personal data.',
    tooltip3:
      'We do not share any personal data with KAHOOT! The KAHOOT! button is only shown to teachers using this service.',
  },
  reflection_questions: {
    button_label: 'Reflection Questions',
    questions_for_prefix: 'Reflection questions for',
    questions_for_suffix: '',
  },
  curriculum: {
    curriculum_title_1: 'The film covers parts of the following key content for ',
    curriculum_title_2: ':',
    grade_prefix: '',
  },
  games: {
    match_the_pairs: {
      title: 'Matcha',
      name: 'Match the pairs',
      description: 'Practice the concepts with this exercise',
      start_game_condition: "There're should be at least 2 pairs to start a new game.",
    },
    start_text: 'Start exercise',
    restart_text: 'Redo the exercise',
    finish_text: 'Finish the exercise',
  },
  page_not_found: {
    title: '404 Not Found',
    description: 'The page you are looking for does not exist!',
    action: 'To the homepage',
  },
  area_guide: {
    go_to: 'Get started',
    click_to_see: 'Click to see explanation',
    greppa: 'Get started with',
    show_supporting_language: 'Show supporting language',
    supporting_language_explainer: 'Select the supporting language you want to show',
    tooltip_show_supporting_language: 'Toggle on/off to show/hide supporting language',
    navigation: {
      item_1: 'Pre-understanding',
      item_1_1: 'What do I already know?',
      item_1_2: 'Think for a moment',
      item_1_3: 'Look at the pictures',
      item_2: 'Motivation',
      item_2_1: 'Why should I learn this?',
      item_2_2: 'Did you know that...',
      item_2_3: 'Reflect for a moment...',
      item_3: 'Movies',
      item_4: 'Keywords',
    },
  },
} as const;
