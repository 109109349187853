const realmToShard = (realm: string): string => {
  switch (realm) {
    case 'us':
      return 'us';
    default:
      return 'eu';
  }
};

export default realmToShard;
