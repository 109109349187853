import { useConfigHelper } from '@ilteducation/config';
import type { GetSchoolUnitRelationsRequest } from '@ilteducation/customer-mgmt-types';
import axios from 'axios';
import qs from 'qs';

const { getApiUrl } = useConfigHelper();

export type ListResponseMetadata = {
  totalItems: number | null;
};

export type ListResponse<T> = {
  data: T[];
  meta: ListResponseMetadata;
};
export type SourceSystemResponse = {
  type: string;
  name: string;
};

export type SchoolUnitRelation = {
  role: 'employee' | 'guardian' | 'student';
  externalId?: string | null;
  sourceSystem?: SourceSystemResponse;
  isAdministrator: boolean;
  user: {
    legacyId?: string;
    uid: string;
    name: string;
    userName: string;
    email: string | null;
    externalId: string | null;
    active: boolean;
    createdAt: number | Date | null; // TODO. Check if number type is valid here.
    updatedAt: number | Date | null; // TODO. Check if number type is valid here.
    deactivatedAt: number | Date | null; // TODO. Check if number type is valid here.
  };
  schoolUnit?: {
    legacyId: string;
    uid: string;
    name: string;
    active: boolean | null;
    address: string | null;
    postalCode: string | null;
    parent: {
      legacyId: string;
      uid: string;
      name: string;
      active: boolean | null;
      userManagementDisabled: boolean | null;
    } | null;
  };
};

// stolen from https://github.com/ilteducation/customer-mgmt/blob/develop/src/models/responses/get-school-unit-relations.ts
export type GetSchoolUnitRelationsResponse = ListResponse<SchoolUnitRelation>;

export const getOrganisations = async (
  params: GetSchoolUnitRelationsRequest,
): Promise<GetSchoolUnitRelationsResponse> => {
  const query = qs.stringify(params);

  const response = await axios.get<GetSchoolUnitRelationsResponse>(
    `${getApiUrl('customer-mgmt')}/userSchoolUnits?${query}`,
  );

  return response.data;
};
