const persistedIltAuthState = localStorage.getItem('ilt-auth.tokens');
if (persistedIltAuthState) {
  const state = JSON.parse(persistedIltAuthState);
  if (state.accessToken && !state.refreshToken) {
    // old, partial state - clear it!
    localStorage.removeItem('ilt-auth.tokens');
  }
}

export default {};
