import { watchEffect } from 'vue';
import { useUserContext } from '@ilteducation/ilt-auth';
import { useActiveIdentity } from '@ilteducation/content-collections';

const useIdentityActivation = () => {
  const userContext = useUserContext();
  const { identity, setActive } = useActiveIdentity();

  watchEffect(() => {
    const userId = userContext.user.uid;

    if (identity?.userId === userId) {
      return;
    }

    setActive({ userId });
  });
};

export default useIdentityActivation;
