import type { BookV1 } from '@ilteducation/content-registry-types';
import axios from 'axios';
import { useConfigHelper } from '@ilteducation/config';

const { getApiUrl } = useConfigHelper();

const CDN_DOMAIN = process.env.VITE_CDN_DOMAIN;

export const getById = (id: string) =>
  axios.get(`${getApiUrl('content-registry', true)}/${id}`).then((response) => response.data as BookV1);

export const getByLegacyId = (id: string, realm: string) =>
  axios
    .get(`${getApiUrl('content-registry', true)}/legacy/Book/${realm}/${id}`)
    .then((response) => response.data as BookV1);

export const getByIds = (ids: string[]) =>
  axios
    .get(`${getApiUrl('content-registry', true)}/books/ids=${ids.join(',')}`)
    .then((response) => response.data as BookV1[]);

export const getContentDownloadUrl = (id: string) =>
  axios
    .get(`${getApiUrl('content-registry', true)}/${id}/contentUrls?allContent=true`)
    .then((response) => `${response.data.url}/` as string);

export const getCoverUrl = (id: string) => `${CDN_DOMAIN}/bookCovers/${id}/cover.png`;
