import { computed } from 'vue-demi';
import { defineStore, storeToRefs } from 'pinia';
import type { AccessTokenPayload, IdTokenPayload } from '@ilteducation/auth';
import jwtDecode from 'jwt-decode';
import { logout as requestLogout } from './api';
import { Subscription, User } from './domain';
import useTokensStore from './use-tokens';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACR_BASIC = 'http://ilt.cloud/assurance/basic';
const ACR_SECURE = 'http://ilt.cloud/assurance/secure';

const AMR_GAR = 'http://ilt.cloud/auth-method/gar';
const AMR_DOMINO = 'http://ilt.cloud/auth-method/domino';

// Dummy token and user for when it's unknown, e.g. when in a native-app's webview
const DUMMY_ID_TOKEN: IdTokenPayload = {
  name: 'Unknown',
  preferred_username: 'unknown',
  sub: '0',
  acr: 'http://inlasningstjanst.se/assurance/basic',
  amr: ['pwd'],
  aud: '',
  auth_time: 0,
  exp: 0,
  iat: 0,
  iss: '',
};
const UNKNOWN_REALM = 'unknown';

const UNKNOWN_USER: User = {
  uid: 'no-one',
  userName: DUMMY_ID_TOKEN.preferred_username ?? 'unknown',
  name: DUMMY_ID_TOKEN.name ?? 'Unknown',
  oldId: 0,
};

const decodeTokens = ({
  idToken,
  accessToken,
}: {
  idToken?: string;
  accessToken: string;
}): { idToken: IdTokenPayload; accessToken: AccessTokenPayload } => {
  const { scope, ...accessTokenPayload } = jwtDecode(accessToken) as Omit<AccessTokenPayload, 'scopes'> & {
    scope: string;
  };

  return {
    idToken: idToken ? (jwtDecode(idToken) as IdTokenPayload) : DUMMY_ID_TOKEN,
    accessToken: { ...accessTokenPayload, scopes: scope.split(' ') },
  };
};

const userFromTokens = ({
  accessToken,
  idToken,
}: {
  accessToken: AccessTokenPayload;
  idToken: IdTokenPayload;
}): User => ({
  uid: accessToken.sub,
  userName: idToken.preferred_username ?? 'unknown',
  name: idToken.name ?? 'Unknown',
  oldId: Number.parseInt(idToken.sub, 10),
});

export type LogoutParams = {
  provider?: {
    path: string;
    params: Record<string, unknown>;
  };
};

const useUserContext = defineStore('ilt-auth-user-context', () => {
  const tokensStore = useTokensStore();

  const { isInitialised, tokens } = storeToRefs(tokensStore);

  const decodedTokens = computed(() => (tokens.value ? decodeTokens(tokens.value) : undefined));

  const isLoggedIn = computed(() => tokens.value?.accessToken !== undefined);
  const authTime = computed(() =>
    decodedTokens.value ? new Date(decodedTokens.value.accessToken.auth_time * 1000) : undefined,
  );
  const realm = computed(() => decodedTokens?.value?.accessToken.realm ?? UNKNOWN_REALM);
  const user = computed<User>(() => (decodedTokens?.value ? userFromTokens(decodedTokens?.value) : UNKNOWN_USER));
  const amr = computed(() => decodedTokens.value?.accessToken.amr);
  const isGar = computed(() => !!decodedTokens?.value?.accessToken.amr.find((v) => v === AMR_GAR) ?? false);
  const isDomino = computed(() => !!decodedTokens?.value?.accessToken.amr.find((v) => v === AMR_DOMINO) ?? false);
  const subscriptions = computed<Subscription[]>(() => decodedTokens?.value?.accessToken.subscriptions ?? []);
  const roles = computed(() => decodedTokens?.value?.accessToken.roles ?? []);
  const hasRole = computed(() => (role: string) => roles.value.some((r) => r.value === role));
  const actor = computed(() =>
    decodedTokens?.value?.idToken.act
      ? { uid: decodedTokens.value.idToken.act.sub, name: decodedTokens.value.idToken.act.name }
      : undefined,
  );
  const isActing = computed(() => actor.value !== undefined);
  const scopes = computed(() => decodedTokens?.value?.accessToken.scopes ?? []);
  const isMFA = computed(() => decodedTokens?.value?.accessToken.acr === ACR_SECURE);

  const isActiveConsumerMarketUser = computed(() => {
    if (!subscriptions.value || subscriptions.value.length === 0 || subscriptions.value.length > 1) {
      return false;
    }

    if (!roles.value || roles.value.length === 0 || roles.value.length > 1) {
      return false;
    }

    const isOnlyGuardian = roles.value.length === 1 && roles.value.some((role) => role.value === 'guardian');
    const hasOnlyOneConsumerSubscription =
      subscriptions.value.length === 1 &&
      subscriptions.value.some((subscription) => subscription.type.endsWith('consumer'));

    return isOnlyGuardian && hasOnlyOneConsumerSubscription;
  });

  const hasScope = (scope: string) => scopes.value.some((s) => s === scope);

  const logout = async (params?: LogoutParams): Promise<void> => {
    if (params?.provider) {
      const { provider } = params;

      await requestLogout(provider.path, { ...provider.params, access_token: tokens.value?.accessToken }).catch(
        () => {},
      ); // silently fail
    }

    return tokensStore.clearTokens();
  };

  return {
    actor,
    isActing,
    isInitialised,
    isLoggedIn,
    isMFA,
    authTime,
    realm,
    user,
    amr,
    isGar,
    isDomino,
    subscriptions,
    roles,
    hasRole,
    scopes,
    isActiveConsumerMarketUser,
    hasScope,
    logout,
  };
});

export default useUserContext;
