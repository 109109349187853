export type SubscriptionConfig = {
  key: string;
  type: string;
};

const subscriptionConfigs: SubscriptionConfig[] = [
  {
    key: 'manabua-middle-school',
    type: 'immigrant-m',
  },
  {
    key: 'manabua-secondary-school',
    type: 'immigrant-h',
  },
  {
    key: 'manabua-vo-upper-secondary-school',
    type: 'immigrant-g-vo',
  },
  {
    key: 'manabua-bf-upper-secondary-school',
    type: 'immigrant-g-bf',
  },
  {
    key: 'manabua-ft-upper-secondary-school',
    type: 'immigrant-g-ft',
  },
];

export default subscriptionConfigs;
