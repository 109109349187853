import psl from 'psl';
import realmToShard from './realm-shard-mapper';
import realmToDomain from './realm-domain-mapper';
import realmTopDomain from './realm-top-domain-mapper';

const configHelper = {
  // Standard locale used by Stripe, [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag)
  countryLocale: {
    de: 'de',
    fi: 'fi',
    fr: 'fr',
    no: 'nb',
    nl: 'nl',
    se: 'sv',
    uk: 'en-GB',
    us: 'en-US',
  },
  overrideEnv: undefined,

  host: () => window.location.hostname,
  setEnvironment(env) {
    configHelper.overrideEnv = env;
  },
  environment: () => {
    if (configHelper.overrideEnv) {
      return configHelper.overrideEnv;
    }
    if (process.env.ENVIRONMENT) {
      return process.env.ENVIRONMENT;
    }
    if (process.env.VUE_APP_ENVIRONMENT) {
      return process.env.VUE_APP_ENVIRONMENT;
    }
    return process.env.VITE_ENVIRONMENT;
  },
  isLocal: () => ['dev', 'development', 'devlocal'].includes(configHelper.environment()), // variation that needs to be harmonised...
  isStaging: () => configHelper.environment() === 'staging',
  isProd: () => configHelper.environment() === 'production',

  getCountryCode() {
    const host = configHelper.host();

    if (host.endsWith('cloud')) {
      // External call in test
      const match = host.match(/(\w+)(?=\.dev.ilt.cloud)/);
      return match[0];
    }

    if (configHelper.isLocal()) {
      // Internal local call
      return 'se';
    }

    const { tld } = psl.parse(host);
    if (!tld) return 'se';

    switch (tld) {
      case 'co.uk':
        return 'uk';
      case 'com':
        return 'us';
      default:
        return tld;
    }
  },

  getShard: () => realmToShard(configHelper.getCountryCode()),

  getLocale: () => configHelper.countryLocale[configHelper.getCountryCode().toLowerCase()] || 'en',

  getUrl: (serviceName, withoutProtocol, predefinedDomain) => {
    const proto = withoutProtocol ? '' : 'https://';
    let isStaging = configHelper.isStaging();

    if (configHelper.isLocal() && configHelper.environment() !== 'production') {
      isStaging = true;
    }

    let domain = predefinedDomain;
    if (!predefinedDomain) {
      domain = realmToDomain(configHelper.getCountryCode());
    }
    const subdomainSuffix = isStaging ? '-dev' : '';

    return `${proto}${serviceName}${subdomainSuffix}.${domain}`;
  },

  getAppUrl: (appName, domain) => {
    if (domain) {
      switch (configHelper.environment()) {
        case 'staging':
          return `https://${appName}-dev.${domain}`;
        default:
          return `https://${appName}.${domain}`;
      }
    }

    const tld = realmTopDomain[configHelper.getCountryCode()];
    switch (configHelper.environment()) {
      case 'dev':
        return `https://app-dev.${appName}.${configHelper.getCountryCode()}`;
      case 'staging':
        return `https://app-dev.${appName}.${tld}`;
      default:
        return `https://app.${appName}.${tld}`;
    }
  },

  getApiUrl: (apiName, isGlobal) => {
    const shard = isGlobal ? 'global' : realmToShard(configHelper.getCountryCode());

    switch (configHelper.environment()) {
      case 'devlocal': // TODO remove legacy environment
      case 'dev':
        return `https://api.${shard}.staging.ilteducation.com/${apiName}`;
      case 'staging':
        return `https://api.${shard}.staging.ilteducation.com/${apiName}`;
      default:
        return `https://api.${shard}.prod.ilteducation.com/${apiName}`;
    }
  },
};

export default configHelper;
