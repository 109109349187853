import { defineStore } from 'pinia';
import { computed, ref } from 'vue-demi';
import jwtDecode from 'jwt-decode';
import type { AccessTokenPayload } from '@ilteducation/auth';
import { PluginListenerHandle } from '@capacitor/core';
import { tokensByRefreshToken } from './api';
import { load, persist, remove } from './storage';
import { checkIsOnline, createOnlineListener } from './network';

export type Tokens = {
  accessToken: string;
  idToken?: string;
  refreshToken?: string;
};

const STORAGE_KEY_TOKENS = 'ilt-auth.tokens';

const REFRESH_WINDOW_SECONDS = 60;

/**
 * @param expiresAt unix timestamp in seconds when the token expires
 * @returns number of milliseconds until the token should be refreshed
 */
const calculateNextRefreshIn = (expiresAt: number): number => {
  const currentTimestampInSecond = Date.now() / 1000;

  const refreshInSeconds = expiresAt - currentTimestampInSecond - REFRESH_WINDOW_SECONDS;
  if (refreshInSeconds < 0) {
    return 0;
  }

  return refreshInSeconds * 1000;
};

type State = {
  accessToken: string;
  clientId?: string;
  idToken?: string;
  refreshToken?: string;
  refreshHandle?: NodeJS.Timeout;
  networkStatusListenerHandle?: PluginListenerHandle;
};

const useTokens = defineStore('ilt-auth-tokens', () => {
  const state = ref<State>();
  const isInitialised = ref(false);

  const acceptTokens = async (tokens: Tokens) => {
    await persist(STORAGE_KEY_TOKENS, tokens);

    let refreshHandle;
    let clientId;
    let networkStatusListenerHandle: PluginListenerHandle | undefined;

    const { refreshToken } = tokens;
    if (refreshToken) {
      const { aud, exp } = jwtDecode(tokens.accessToken) as AccessTokenPayload;

      clientId = aud;

      const isOnline = await checkIsOnline();
      const refreshInMs = calculateNextRefreshIn(exp);
      if (isOnline || refreshInMs > 0) {
        // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
        refreshHandle = setTimeout(() => {
          load<Tokens>(STORAGE_KEY_TOKENS).then((persistedTokens) => {
            // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
            refreshTokens(persistedTokens!, aud);
          });
        }, refreshInMs);
      } else {
        // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
        networkStatusListenerHandle = await createOnlineListener(() => refreshTokens(tokens, aud));
      }
    }

    state.value = { ...tokens, clientId, refreshHandle, networkStatusListenerHandle };
  };

  const clearTokens = async () => {
    await remove(STORAGE_KEY_TOKENS);

    if (state.value?.refreshHandle) {
      clearTimeout(state.value.refreshHandle);
    }
    if (state.value?.networkStatusListenerHandle) {
      state.value.networkStatusListenerHandle.remove();
    }

    state.value = undefined;
  };

  const refreshTokens = async (tokens: Tokens, clientId: string): Promise<void> => {
    const isOnline = await checkIsOnline();
    if (!isOnline) {
      // simply pass the old tokens
      return acceptTokens(tokens);
    }

    return tokensByRefreshToken({
      refreshToken: tokens.refreshToken!,
      clientId,
    })
      .then(acceptTokens)
      .catch(clearTokens);
  };

  const forceTokensRefresh = async () =>
    load<Tokens>(STORAGE_KEY_TOKENS).then((persistedTokens) => {
      if (!persistedTokens) {
        throw new Error('No tokens available');
      }

      const { aud } = jwtDecode(persistedTokens.accessToken) as AccessTokenPayload;
      return refreshTokens(persistedTokens, aud);
    });

  const tokens = computed(() =>
    state.value ? { idToken: state.value.idToken, accessToken: state.value.accessToken } : null,
  );

  const hasTokensExpired = () => {
    if (!state.value?.accessToken) {
      return true;
    }

    const { exp: expiryInSeconds } = jwtDecode(state.value.accessToken) as AccessTokenPayload;
    const nowInSeconds = Date.now() / 1000;

    return nowInSeconds > expiryInSeconds;
  };

  // Load persisted tokens on initial load
  load<Tokens>(STORAGE_KEY_TOKENS).then((persistedTokens) => {
    if (!persistedTokens) {
      isInitialised.value = true;
      return;
    }

    const { aud, exp } = jwtDecode(persistedTokens.accessToken) as AccessTokenPayload;

    const refreshInMs = calculateNextRefreshIn(exp);
    if (refreshInMs > REFRESH_WINDOW_SECONDS * 1000) {
      // start the normal, scheduled flow
      acceptTokens(persistedTokens).finally(() => {
        isInitialised.value = true;
      });
    } else if (persistedTokens.refreshToken) {
      // refresh immediately
      refreshTokens(persistedTokens, aud).finally(() => {
        isInitialised.value = true;
      });
    } else {
      isInitialised.value = true;
    }
  });

  return {
    isInitialised,
    tokens,
    acceptTokens,
    clearTokens,
    hasTokensExpired,
    forceTokensRefresh,
  };
});

export default useTokens;
