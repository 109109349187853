<template>
  <v-bottom-navigation class="d-md-none bottom-nav" height="64">
    <v-tabs align-tabs="center" class="text-secondary" slider-color="secondary">
      <v-tab class="pb-2" stacked :to="{ name: 'Videos' }" :ripple="false">
        <v-icon size="24" class="text-secondary" icon="custom:videoIcon"></v-icon>
        <div class="tab-text" :class="capitalizationClass">
          {{ t(l.tabs.films) }}
        </div>
      </v-tab>
      <v-tab stacked class="pb-2" :to="{ name: 'Library' }" :ripple="false">
        <v-icon size="24" class="text-secondary" icon="custom:libraryIcon"></v-icon>
        <div class="tab-text" :class="capitalizationClass">
          {{ t(l.tabs.library) }}
        </div>
      </v-tab>
      <v-tab v-if="showGuides" class="pb-2" stacked :to="{ name: 'Guides' }" :ripple="false">
        <v-icon size="24" class="text-secondary" icon="custom:guideIcon"></v-icon>
        <div class="tab-text" :class="capitalizationClass">
          {{ t(l.tabs.guides_short) }}
        </div>
      </v-tab>
    </v-tabs>
  </v-bottom-navigation>
</template>

<script setup lang="ts">
import config from '@/config/ConfigProvider';
import { useLocale, useLocalisation } from '@/plugins/translation';
import { computed } from 'vue';

const showGuides = config.value('showGuides');

const { t, l } = useLocale();
const capitalizationClass = computed(() => {
  const { language } = useLocalisation();
  return language.value === 'en-GB' ? 'text-capitalize' : 'text-capitalize-first';
});
</script>
<style scoped lang="scss">
@use '@/foundations/theme/settings';
.bottom-nav {
  padding: 12px 16px 0;
  z-index: 10;
}
.tab-text {
  font-size: 12px;
}

.text-capitalize-first {
  text-transform: lowercase !important;
}

.text-capitalize-first::first-letter {
  text-transform: uppercase !important;
}
</style>
