import { computed, ComputedRef } from 'vue';
import { Subscription, useUserContext } from '@ilteducation/ilt-auth';
import subscriptionConfigs, { SubscriptionConfig } from '@/foundations/services/constants/subscriptions';

const useSubscriptions = (): ComputedRef<SubscriptionConfig[]> => {
  const userContext = useUserContext();

  return computed(() =>
    Object.values(subscriptionConfigs).filter((s) =>
      userContext.subscriptions.some((activeSubscription: Subscription) => activeSubscription.type === s.type),
    ),
  );
};

export default useSubscriptions;
