<script setup lang="ts">
const icons = {
  'icon-cd': '\ue91b',
  'icon-settings': '\ue914',
  'icon-bokhyllan': '\ue915',
  'icon-play': '\ue916',
  'icon-add': '\ue917',
  'icon-add-to-student': '\ue918',
  'icon-fulltext': '\ue919',
  'icon-talbok': '\ue91a',
  'icon-angle-down': '\ue900',
  'icon-angle-up': '\ue901',
  'icon-angle-right': '\ue902',
  'icon-angle-left': '\ue903',
  'icon-dots-three-vertical': '\ue904',
  'icon-dots-three-horizontal': '\ue91c',
  'icon-location': '\ue905',
  'icon-close': '\ue906',
  'icon-check': '\ue907',
  'icon-delete': '\ue920',
  'icon-location_city': '\ue908',
  'icon-school': '\ue909',
  'icon-phone': '\ue90a',
  'icon-chat_bubble': '\ue90b',
  'icon-envelope': '\ue90c',
  'icon-search': '\ue90d',
  'icon-person': '\ue90e',
  'icon-checkmark': '\ue90f',
  'icon-arrow_forward': '\ue910',
  'icon-arrow_first': '\ue91d',
  'icon-arrow_last': '\ue91e',
  'icon-rss_feed': '\ue911',
  'icon-volume_up': '\ue912',
  'icon-clock': '\ue913',
  'icon-change-group': '\ue921',
  'icon-change': '\ue921',
  'icon-edit': '\ue91f',
  'icon-secure': '\ue922',
  'icon-file-upload': '\ue925',
  'icon-introfilmer': '\ue926',
  'icon-subtitles': '\ue927',
  'icon-fullscreen': '\ue928',
  'icon-volume-off': '\ue929',
  'icon-pause': '\ue92a',
  'icon-theatermode': '\ue92b',

  'icon-share': '\uea82',
  'icon-subtitle-large': '\ue92c',
  'icon-subtitle-small': '\ue92d',

  'icon-subtract': '\ue92e',
  'icon-school-fr': '\ue92f',
  'icon-epub': '\ue932',

  'icon-grid': '\ue930',
  'icon-list': '\ue931',

  'icon-menu': '\ue931',
  'icon-signon': '\ue931',
  'icon-signout': '\ue934',
  'icon-edit-users': '\ue933',
  'icon-lightbulb': '\ue935',
  'icon-thumbsup': '\ue936',
  'icon-facebook': '\ue937',
  'icon-youtube': '\ue938',
  'icon-linkedin': '\ue939',
  'icon-instagram': '\ue93a',
  'icon-twitter': '\ue93b',
  'icon-mynewsdesk': '\ue93c',
  'icon-home': '\ue93d',
};

defineProps<{
  icon: keyof typeof icons;
  tag: string;
}>();
</script>

<template>
  <component :is="tag" id="ilt-icon">{{ icons[icon] }}</component>
</template>

<style scoped lang="scss">
#ilt-icon {
  font-family: 'ilt';
  font-style: normal;
}
</style>
