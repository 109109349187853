<template>
  <v-system-bar v-if="actor" color="primary" window class="font-weight-bold">
    <v-icon icon="mdi-drama-masks" size="large"></v-icon>
    <span class="text-large ml-2">{{ message }}</span>
    <v-spacer></v-spacer>
    <v-btn variant="text" @click.native="logout()">
      <v-icon icon="mdi-logout" size="large"></v-icon>
      <v-tooltip location="bottom" open-delay="300" activator="parent">Logout</v-tooltip>
    </v-btn>
  </v-system-bar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import useAuthentication from '../use-authentication';
import useUserContext from '../use-user-context';
import { computed } from 'vue-demi';

const authentication = useAuthentication();
const userContext = useUserContext();
const { actor, user } = storeToRefs(userContext);

const message = computed(() => `${actor.value?.name ?? 'Unknown'} logged in as ${user.value.name}`);

const logout = async () => {
  await userContext.logout();

  window.location.href = authentication.createLogoutLink(window.location.href);
};
</script>

<style scoped>
.v-system-bar .v-icon {
  opacity: 1;
}
</style>
