import _ from 'lodash';
import { useUserContext } from '@ilteducation/ilt-auth';
import { exerciseApi } from '@/api/exerciseApi';

export default {
  namespaced: true,

  state: {
    loadingText: 'quiz.loading_quiz',
    isLoading: true,
    isQuizMode: true,
    exercises: [],
    currentQuiz: null,
    currentQuestion: 1,
    answers: [],
    correctAnswers: [],
    submission: null,
    submissions: [],
    exerciseTracker: [],
    isError: false,
  },

  actions: {
    fetchExercises({ commit, state, dispatch }, resourceId) {
      exerciseApi.getExercises(resourceId).then((response) => {
        commit('RESET_QUIZ');
        // response object has its own param `data`
        // and the response itself contains data in a structure when paginated requests are called

        let sortIndex = Math.max(...response.data.data.map((ex) => ex.sort ?? 0)) + 1; // put quizzes that are missing the sort property at the end of the list
        const quizData = response.data.data
          // eslint-disable-next-line no-plusplus
          .map((ex) => (ex.type === 'quiz' ? { ...ex, sort: ex.sort ?? sortIndex++ } : ex));
        commit('SET_QUIZ_DATA', quizData);
        commit('SET_ACTIVE_QUIZ');

        if (state.currentQuiz?.id) {
          dispatch('fetchExerciseTracker', resourceId);
        }

        // Hidden until we have "Archived" status on submission
        // Fetch submissions only if resource has Quiz
        // if (state.currentQuiz?.id) {
        //   dispatch('fetchSubmissions');
        // }
      });
    },
    nextQuestion({ commit, state }) {
      commit('SET_IS_LOADING', true);
      const submissionData = {};
      const mappedAnswers = {};

      state.answers.forEach((answer) => {
        mappedAnswers[state.currentQuiz.content[answer.questionId].id] = {
          optionId: answer.optionId,
          type: 'ClosedEndedQuestionAnswer',
        };
      });

      submissionData.answers = {
        [state.currentQuiz.id]: mappedAnswers,
      };
      submissionData.status = 'InProgress';

      if (state.submission) {
        exerciseApi
          .updateSubmission(state.submission.id, submissionData)
          .then(() => commit('SET_SUBMISSION', { ...state.submission, answers: submissionData.answers }));
      }

      if (state.currentQuestion === 1 && !state.submission) {
        commit('SET_IS_LOADING', true);

        const userContext = useUserContext();

        exerciseApi
          .createSubmission({
            contextId: state.currentQuiz.id,
            context: {
              type: 'Quiz',
              exercise: state.currentQuiz,
              realm: userContext.realm,
            },
          })
          .then(
            (response) =>
              commit('SET_SUBMISSION', {
                ...response.data,
                answers: submissionData.answers,
              }),
            commit('SET_IS_LOADING', false),
          );
      }

      commit('SET_NEXT_QUESTION');
    },
    prevQuestion({ commit }) {
      commit('SET_PREV_QUESTION');
    },
    answerQuestion({ commit }, optionId) {
      commit('SET_ANSWER_QUESTION', optionId);
    },
    activateResultMode({ commit }) {
      commit('SET_RESULT_MODE');
    },
    correctAnswers({ commit }, data) {
      commit('CORRECTED_ANSWERS', data);
    },
    setGradingStatus({ commit }) {
      commit('SET_GRADING_STATUS');
    },
    resetQuiz({ commit, dispatch }, hardReset) {
      if (!hardReset) {
        dispatch('fetchSubmissions');
      } else {
        commit('SET_SUBMISSION', null);
      }
      commit('RESET_QUIZ');
    },
    setPreviousQuiz({ commit }) {
      commit('SET_PREVIOUS_QUIZ_AS_CURRENT_QUIZ');
    },
    setNextQuiz({ commit }) {
      commit('SET_NEXT_QUIZ_AS_CURRENT_QUIZ');
    },
    setCorrectAnswers({ commit }) {
      commit('SET_CORRECT_ANSWERS');
    },
    setExerciseTracker({ commit }, data) {
      commit('SET_EXERCISE_TRACKER', data);
    },
    async compareAnswers({ commit, state }) {
      const submissionData = {};
      const mappedAnswers = {};

      state.answers.forEach((answer) => {
        mappedAnswers[state.currentQuiz.content[answer.questionId].id] = {
          optionId: answer.optionId,
          type: 'ClosedEndedQuestionAnswer',
        };
      });

      submissionData.answers = {
        [state.currentQuiz.id]: mappedAnswers,
      };
      submissionData.status = 'Submitted';

      if (!state.submission) {
        commit('SET_IS_LOADING', true);
        const userContext = useUserContext();

        const newSubmissionResponse = await exerciseApi.createSubmission({
          contextId: state.currentQuiz.id,
          context: {
            type: 'Quiz',
            exercise: state.currentQuiz,
            realm: userContext.realm,
          },
        });
        commit('SET_SUBMISSION', {
          ...newSubmissionResponse.data,
          answers: submissionData.answers,
        });
      }
      try {
        await exerciseApi.updateSubmission(state.submission.id, submissionData);
        commit('SET_SUBMISSION', {
          ...state.submission,
          answers: submissionData.answers,
          status: submissionData.status,
        });
      } catch (error) {
        commit('SET_IS_ERROR', true);
      } finally {
        commit('SET_IS_LOADING', false);
      }

      commit('SET_CORRECT_ANSWERS');
      if (state.correctAnswers.filter((answer) => answer.correct).length === state.currentQuiz.content.length) {
        commit('SET_EXERCISE_TRACKER', [...state.exerciseTracker, ...[state.currentQuiz.id]]);
      }
      commit('SET_RESULT_MODE');
    },
    fetchSubmissions({ commit, state }) {
      exerciseApi.getSubmissions(state.currentQuiz.id, 'Quiz').then((submissions) => {
        commit('SET_SUBMISSIONS', submissions.data);
        commit(
          'SET_SUBMISSION',
          submissions.data.find(
            (submission) =>
              state.currentQuiz.id === submission.context.exercise.id && submission.status === 'InProgress',
          ),
        );

        // Set answers only if submission has answers
        if (state.submission?.answers[state.currentQuiz.id]) {
          commit(
            'SET_ANSWERS',
            _.entries(state.submission.answers[state.currentQuiz.id]).map(([questionId, answer]) => ({
              ...answer,
              questionId,
            })),
          );
        }
        commit('SET_CURRENT_QUESTION');
      });
    },
    fetchExerciseTracker({ commit, state }, resourceId) {
      exerciseApi.getExerciseTracker(resourceId).then((response) => {
        commit('SET_EXERCISE_TRACKER', response.data);
        state.exercises
          .filter((el) => el.type === 'quiz')
          .forEach((item, index) => {
            if (state.exerciseTracker.length === index) {
              commit('SET_ACTIVE_QUIZ', item);
            }
          });
      });
    },
  },

  mutations: {
    SET_QUIZ_DATA(state, exercises) {
      state.exercises = exercises.length > 1 ? exercises.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0)) : exercises;

      state.isLoading = false;
    },
    SET_ACTIVE_QUIZ(state, quiz = null) {
      state.currentQuiz = quiz ?? state.exercises.find((el) => el.type === 'quiz');
    },
    RESET_QUIZ(state) {
      state.isError = false;
      state.currentQuestion = 1;
      state.answers = [];
      state.correctAnswers = [];
      state.isQuizMode = true;
    },
    // eslint-disable-next-line consistent-return
    SET_NEXT_QUESTION(state) {
      state.isLoading = false;
      if (state.currentQuestion + 1 > state.currentQuiz.content.length) {
        return false;
      }
      state.currentQuestion += 1;
    },
    // eslint-disable-next-line consistent-return
    SET_PREV_QUESTION(state) {
      if (state.currentQuestion === 1) {
        return false;
      }
      state.currentQuestion -= 1;
    },
    SET_ANSWER_QUESTION(state, optionId) {
      const exAnswer = state.answers.find((answer) => answer.questionId === state.currentQuestion - 1);

      if (exAnswer) {
        exAnswer.optionId = optionId;
        return;
      }

      state.answers.push({ questionId: state.currentQuestion - 1, optionId });
    },
    SET_RESULT_MODE(state) {
      state.isQuizMode = false;
    },
    SET_CORRECT_ANSWERS(state) {
      state.isLoading = false;
      state.correctAnswers = [];
      for (let index = 0; index < state.currentQuiz.content.length; ++index) {
        const currentAnswer = state.answers.find((answer) => answer.questionId === index);

        state.correctAnswers.push({
          questionId: index,
          optionId: currentAnswer?.optionId,
          correctOptionId: state.currentQuiz.content[index].answer,
          correct: state.currentQuiz.content[index].answer === currentAnswer?.optionId,
        });
      }
      state.loadingText = 'quiz.loading_quiz';
    },
    SET_GRADING_STATUS(state) {
      state.loadingText = 'quiz.correcting_answers';
      state.isLoading = true;
    },
    SET_SUBMISSION(state, payload) {
      state.submission = payload;
    },
    SET_SUBMISSIONS(state, submissions) {
      state.submissions = submissions;
    },
    SET_ANSWERS(state, answers) {
      state.answers = answers;
    },
    SET_PREVIOUS_QUIZ_AS_CURRENT_QUIZ(state) {
      const quizzes = state.exercises.filter((el) => el.type === 'quiz');
      const currentIndex = quizzes.findIndex((el) => el.id === state.currentQuiz.id);

      if (currentIndex !== 0) {
        state.currentQuiz = quizzes[currentIndex - 1];
      }
    },
    SET_NEXT_QUIZ_AS_CURRENT_QUIZ(state) {
      const quizzes = state.exercises.filter((el) => el.type === 'quiz');
      const currentIndex = quizzes.findIndex((el) => el.id === state.currentQuiz.id);

      if (currentIndex < quizzes.length - 1) {
        state.currentQuiz = quizzes[currentIndex + 1];
      }
    },
    SET_CURRENT_QUESTION(state) {
      state.currentQuestion = state.answers.length + 1;
    },
    SET_EXERCISE_TRACKER(state, exerciseTracker) {
      state.exerciseTracker = exerciseTracker;
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_IS_ERROR(state, isError) {
      state.isError = isError;
    },
  },

  getters: {
    getCorrectAnswers: (state) => state.correctAnswers,
    isQuizMode: (state) => state.isQuizMode,
    getAnswers: (state) => state.answers,
    getExercises: (state) => state.exercises,
    getQuizzes: (state) => state.exercises.filter((el) => el.type === 'quiz'),
    getKahootLinks: (state) => state.exercises.filter((el) => el.type === 'kahoot'),
    getReflectionTasks: (state) => state.exercises.filter((el) => el.type === 'reflection'),
    getCurrentQuestion: (state) => state.currentQuestion,
    getQuestionTotal: (state) => state.currentQuiz.content.length,
    isLoading: (state) => state.isLoading,
    isError: (state) => state.isError,
    getLoadingText: (state) => state.loadingText,
    getQuestionHasAnswer: (state) => {
      const exAnswer = state.answers.find((answer) => answer.questionId === state.currentQuestion - 1);

      return !!exAnswer;
    },
    getSubmission: (state) => state.submission,
    getCurrentQuiz: (state) => state.currentQuiz,
    getExerciseTracker: (state) => state.exerciseTracker,
  },
};
