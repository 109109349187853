import { Howl, Howler } from 'howler';

let activeHowl: any = null;

export const getActiveHowl = () => activeHowl;

export const playSound = (url: string, onEnd: any) => {
  stopAllSounds();
  activeHowl = new Howl({
    src: url,
    preload: true,
    html5: true,
    onend() {
      onEnd();
    },
  });
  activeHowl.play();
};

export const playAllSound = (i: number, urls: string[], finalCycle: any, currentCycle: number, onEnd: any) => {
  stopAllSounds();
  currentCycle = currentCycle || 1;
  finalCycle = finalCycle || 'infinite repeat';
  activeHowl = new Howl({
    src: [urls[i]],
    preload: true,
    onend() {
      if (i + 1 == urls.length) {
        playAllSound(0, urls, finalCycle, currentCycle, onEnd);
        onEnd();
      } else {
        playAllSound(i + 1, urls, finalCycle, currentCycle, onEnd);
      }
    },
  });
  currentCycle === finalCycle + 1 && i === 0 ? activeHowl.stop() : activeHowl.play();
  if (i === urls.length - 1) currentCycle++;
};

export const stopAllSounds = () => {
  activeHowl && activeHowl.stop();
};

export const pauseSound = () => {
  activeHowl && activeHowl.pause();
};

export const resumeSound = () => {
  activeHowl && activeHowl.play();
};
