import { createStore } from 'vuex';
import { begreppaStore } from './begreppaStore';
import quiz from './quiz';

/**
 * Store and state below should be separated into quizStore
 */
const initialState = {
  packageVersion: import.meta.env.VITE_APP_VERSION || '0.0.1',
};

const store = createStore({
  strict: true,

  modules: {
    begreppa: begreppaStore,
    quiz,
  },

  state: initialState,

  getters: {
    appVersion: (state) => state.packageVersion,
  },
});

export default store;
