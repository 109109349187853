import axios, { AxiosError } from 'axios';
import { useTokens } from '@ilteducation/ilt-auth';
import * as datadog from '@ilteducation/datadog';

export default function setup() {
  axios.interceptors.request.use((request) => {
    const tokensStore = useTokens();
    if (request.headers.NO_AUTH) {
      // eslint-disable-next-line no-param-reassign
      delete request.headers.NO_AUTH;
      // eslint-disable-next-line no-param-reassign
      delete request.headers.Authorization;
    } else if (tokensStore.tokens && request.headers) {
      // eslint-disable-next-line no-param-reassign
      request.headers.Authorization = `Bearer ${tokensStore.tokens.accessToken}`;
    }
    return request;
  });
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (axios.isAxiosError(error)) {
        const ae: AxiosError = error as AxiosError;
        const context = {
          url: ae.response?.config.url,
          status: ae.response?.status,
          statusText: ae.response?.statusText,
          method: ae.response?.config.method,
        };
        datadog.report(error, context);
      }
      return Promise.reject(error);
    },
  );
}
