import { watchEffect, Plugin } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserContext } from '@ilteducation/ilt-auth';
import { useUserOrganisations } from '@ilteducation/my-organisations';
import { useConfigHelper } from '@ilteducation/config';

declare const StonlyWidget: any;

export type PluginOptions = {
  wid: string;
};

const plugin: Plugin<PluginOptions> = (app, { wid }) => {
  const { countryCode } = useConfigHelper();
  if (countryCode === 'fr') {
    return;
  }

  const scriptId = document.createElement('script');
  scriptId.text = `window.STONLY_WID = "${wid}";`;
  const scriptMain = document.createElement('script');
  scriptMain.text =
    '!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){\n' +
    '  w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,\n' +
    '  (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){\n' +
    '  4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),\n' +
    '  (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())\n' +
    '  }(window,document,"script","https://stonly.com/js/widget/v2/");';
  document.head.appendChild(scriptId);
  document.head.appendChild(scriptMain);

  const { isInitialised, isLoggedIn, user, realm, roles, subscriptions } = storeToRefs(useUserContext());
  watchEffect(async () => {
    if (!isInitialised.value) {
      return;
    }

    if (!isLoggedIn.value) {
      StonlyWidget('cleanup');
    } else {
      const { organisations, load } = useUserOrganisations(10, false);

      try {
        await load();
      } catch (e) {
        console.log(`Failed to load organisations for analytics`, e);
      }
      // Stonly don't support lists of values, so they have to be converted in to strings,
      StonlyWidget('identify', user.value.uid, {
        realm: realm.value,
        roles: roles.value.map((role) => role.value).join(','),
        organisationIds: organisations.value.map((org) => org.schoolUnit?.uid || 'UNKNOWN').join(','),
        subscriptions: subscriptions.value.map((sub) => sub.type).join(','),
      });
    }
  });
};

export default plugin;
