const realmTopDomain: Record<string, string> = {
  de: 'de',
  fi: 'fi',
  fr: 'fr',
  no: 'no',
  nl: 'nl',
  se: 'se',
  uk: 'co.uk',
  us: 'com',
};

export default realmTopDomain;
