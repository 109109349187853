import { computed, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import useUserOrganisationsStore from './user-organisations-store';
import { SchoolUnitRelation } from './api';

const useUserOrganisations = (pageSize: number, loadImmediately = true) => {
  const userOrganisationsStore = useUserOrganisationsStore();
  const { organisations: originalOrganisations, isLoading, hasMore } = storeToRefs(userOrganisationsStore);

  // TS error thrown when compiling other modules using @ilteducation/my-organisations. Due to pinia 2.0 being used.
  // Pinia 2.1 is NOT compatible with vue < 3.3
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const organisations = computed<SchoolUnitRelation[]>(() => originalOrganisations.value ?? []);

  const load = () => userOrganisationsStore.load(pageSize);

  if (loadImmediately && !originalOrganisations.value && !isLoading.value) {
    load();
  }

  return {
    organisations,
    isLoading: readonly(isLoading),
    hasMore,
    load,
  };
};

export default useUserOrganisations;
