import { watchEffect, Plugin } from 'vue';
import { storeToRefs } from 'pinia';
import posthog, { PostHogConfig } from 'posthog-js';
import type { Router } from 'vue-router';
import { useUserContext } from '@ilteducation/ilt-auth';
import { useUserOrganisations } from '@ilteducation/my-organisations';
import { useConfigHelper } from '@ilteducation/config';

export type PluginOptions = {
  apiKey: string;
  router: Router;
  config?: PostHogConfig;
};

const plugin: Plugin<PluginOptions> = (app, { apiKey, router, config }) => {
  const { countryCode } = useConfigHelper();
  if (countryCode === 'fr') {
    return;
  }
  posthog.init(apiKey, { ...config, api_host: 'https://eu.posthog.com' });

  app.provide('posthog', posthog);

  if (config?.capture_pageleave === undefined || config?.capture_pageleave !== false) {
    router.beforeEach(() => {
      posthog.capture('$pageleave');
    });
  }
  if (config?.capture_pageview === undefined || config?.capture_pageview !== false) {
    router.afterEach(() => {
      posthog.capture('$pageview');
    });
  }

  const { isInitialised, isLoggedIn, realm, roles } = storeToRefs(useUserContext());
  watchEffect(async () => {
    if (!isInitialised.value) {
      return;
    }

    if (!isLoggedIn.value) {
      posthog.reset();
    } else {
      const { organisations, load } = useUserOrganisations(10, false);

      try {
        await load();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Failed to load organisations for analytics`, e);
      }

      posthog.people.set({
        realm: realm.value,
        roles: roles.value.map((role) => role.value),
        organisationIds: organisations.value.map((org) => org.schoolUnit?.uid || 'UNKNOWN'),
      });
    }
  });
};

export default plugin;
