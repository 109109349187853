import axios from 'axios';
import {
  AddCollectionContentRequest,
  AddMemberRequest,
  ContentCollection,
  CreateContentCollectionRequest,
  CreateContentCollectionResponse,
  GetContentCollectionResponse,
  GetContentCollectionsResponse,
  MemberRole,
  Page,
  RemoveCollectionContentRequest,
  RemoveMemberRequest,
  ShareableContentCollection,
  UpdateContentCollectionRequest,
  UpdateContentCollectionResponse,
} from '@ilteducation/content-collections-types';
import { GetViewCollectionsResponse } from '@ilteducation/content-views-types';
import { Content } from '@ilteducation/content-registry-types';
import qs from 'qs';
import { useConfigHelper } from '@ilteducation/config';
import { ContentQuery, ViewCollectionsQuery } from './types';

const { getApiUrl } = useConfigHelper();

export const getViewCollections = (
  viewId: string,
  collectionQuery: ViewCollectionsQuery,
  profileId?: string,
): Promise<GetViewCollectionsResponse> => {
  const urlQs = qs.stringify({ ...collectionQuery, profileId }, { arrayFormat: 'repeat' });
  const params = urlQs ? `?${urlQs}` : '';

  return axios
    .get(`${getApiUrl('content-views')}/${viewId}/collections${params}`)
    .then((response) => response.data as GetViewCollectionsResponse);
};

export const getOwnedCollections = (
  context: string,
  profileId?: string,
  cursor?: string,
  limit?: number,
  role?: MemberRole,
): Promise<Page<ShareableContentCollection>> => {
  const urlQs = qs.stringify({ profileId, cursor, limit, role, context });
  const params = urlQs ? `?${urlQs}` : '';

  return axios
    .get(`${getApiUrl('content-collections')}/${params}`)
    .then((response) => response.data as GetContentCollectionsResponse);
};

export const getContentCollection = (collectionId: string, profileId?: string): Promise<ContentCollection | null> =>
  axios
    .get(`${getApiUrl('content-collections')}/${collectionId}${profileId ? `?profileId=${profileId}` : ''}`)
    .then((response) => response.data as GetContentCollectionResponse)
    .catch((error) => {
      if (error.response.status === 404) {
        return Promise.resolve(null);
      }
      return Promise.reject(error);
    });

export const getCollectionContent = (
  collectionId: string,
  contentQuery: ContentQuery,
  profileId?: string,
): Promise<Page<Content>> => {
  const urlQs = qs.stringify({ ...contentQuery, profileId }, { arrayFormat: 'repeat' });
  const params = urlQs ? `?${urlQs}` : '';

  return axios
    .get(`${getApiUrl('content-collections')}/${collectionId}/contents${params}`)
    .then((response) => response.data as Page<Content>);
};

export const create = (payload: CreateContentCollectionRequest): Promise<ContentCollection> =>
  axios
    .post(`${getApiUrl('content-collections')}/`, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => response.data as CreateContentCollectionResponse);

export const destroy = (collectionId: string, profileId?: string): Promise<void> => {
  const urlQs = qs.stringify({ profileId });
  const params = urlQs ? `?${urlQs}` : '';

  return axios.delete(`${getApiUrl('content-collections')}/${collectionId}${params}`);
};

export const update = (
  id: string,
  payload: UpdateContentCollectionRequest,
  profileId?: string,
): Promise<ContentCollection> => {
  const urlQs = qs.stringify({ profileId });
  const params = urlQs ? `?${urlQs}` : '';

  return axios
    .patch(`${getApiUrl('content-collections')}/${id}${params}`, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => response.data as UpdateContentCollectionResponse);
};

export const addMember = (collectionId: string, payload: AddMemberRequest): Promise<void> =>
  axios.post(`${getApiUrl('content-collections')}/${collectionId}/members`, payload, {
    headers: { 'Content-Type': 'application/json' },
  });

export const removeMember = (collectionId: string, payload: RemoveMemberRequest): Promise<void> =>
  axios.post(`${getApiUrl('content-collections')}/${collectionId}/members/remove`, payload, {
    headers: { 'Content-Type': 'application/json' },
  });

export const addContent = (
  collectionId: string,
  payload: AddCollectionContentRequest,
  profileId?: string,
): Promise<void> => {
  const urlQs = qs.stringify({ profileId }, { arrayFormat: 'repeat' });
  const params = urlQs ? `?${urlQs}` : '';

  return axios
    .post(`${getApiUrl('content-collections')}/${collectionId}/contents${params}`, payload)
    .then((response) => response.data);
};

export const removeContent = (
  collectionId: string,
  payload: RemoveCollectionContentRequest,
  profileId?: string,
): Promise<void> => {
  const urlQs = qs.stringify({ profileId }, { arrayFormat: 'repeat' });
  const params = urlQs ? `?${urlQs}` : '';
  return axios
    .post(`${getApiUrl('content-collections')}/${collectionId}/contents/remove${params}`, payload)
    .then((response) => response.data);
};

export const unsubscribeFromCollection = (collectionId: string): Promise<void> => {
  return axios.post(`${getApiUrl('content-collections')}/${collectionId}/unsubscribe`, {});
};

export const subscribeToCollection = (collectionId: string): Promise<void> =>
  axios.post(`${getApiUrl('content-collections')}/${collectionId}/subscribe`).then((response) => response.data);
