import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';

/*
  Due to Capacitor's Preferences using a prefix, which will without care ignore existing keys, we'll
  just use Localstorage directly on web for now...
 */
const isNative = Capacitor.isNativePlatform();

export const persist = async (key: string, value: unknown) => {
  if (isNative) {
    await Preferences.set({ key, value: JSON.stringify(value) });
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const remove = async (key: string) => {
  try {
    if (isNative) {
      await Preferences.remove({ key });
    } else {
      localStorage.removeItem(key);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('could not clean store', error);
  }
};

export const load = async <T>(key: string): Promise<T | null> => {
  let value: string | null;
  if (isNative) {
    const result = await Preferences.get({ key });
    value = result.value;
  } else {
    value = localStorage.getItem(key);
  }

  if (!value) {
    return null;
  }

  return JSON.parse(value) as T;
};

export const loadAndClear = async <T>(key: string): Promise<T | null> => {
  const value = await load<T>(key);
  await remove(key);

  return value;
};
