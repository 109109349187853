import { BasicCollection } from '@ilteducation/content-collections-types';
import { ContentCollection, ContentReference, ContentsByReferences } from './types';
import useActiveIdentity from './use-active-identity';

export const isCollectionOwner = (collection: BasicCollection) => {
  const { identity } = useActiveIdentity();
  // TODO #shareModule check it, removed here checking by profile
  return (collection.owners ?? []).some((owner) => owner.userId === identity.userId);
};

export const isCollectionSubscriber = (collection: BasicCollection): boolean => {
  const { identity } = useActiveIdentity();
  if (collection.subscribers === undefined || collection.subscribers.length === 0) {
    return false;
  }
  return collection.subscribers.some((s) => s.id === identity.userId);
};

export const isCollectionMember = (collection: BasicCollection): boolean => {
  const { identity } = useActiveIdentity();
  if (collection.members === undefined || collection.members.length === 0) {
    return false;
  }
  return collection.members.some((s) => s.id === identity.userId);
};

export const isSpecialCollectionId = (collectionId: string) =>
  collectionId.startsWith('history') || collectionId.startsWith('shared');

export const isCollectionCreator = (collection: BasicCollection) => {
  const { identity } = useActiveIdentity();
  return collection?.creator?.userId === identity.userId;
};

export const isCuratedCollection = (collection: BasicCollection): boolean => collection?.owners === undefined;

export const isFullProjection = (collection: BasicCollection | ContentCollection): collection is ContentCollection =>
  'contents' in collection;

export const addContentsReferences = (
  contents: ContentsByReferences,
  newReferences: ContentReference[],
): ContentsByReferences => {
  const referencesSet = new Set();
  const contentReferences: ContentReference[] = [...contents.references, ...newReferences].filter((r) => {
    const serialisedReference = `${r.type}:${r.id}`;
    const exists = referencesSet.has(serialisedReference);
    referencesSet.add(serialisedReference);

    return !exists;
  });

  return {
    type: 'ContentsByReferences',
    references: contentReferences,
  };
};

export const removeContentsReferences = (
  contents: ContentsByReferences,
  referencesToRemove: ContentReference[],
): ContentsByReferences => {
  const referencesSet = new Set(referencesToRemove.map((r) => `${r.type}:${r.id}`));
  const contentReferences: ContentReference[] = contents.references.filter((r) => {
    const serialisedReference = `${r.type}:${r.id}`;
    return !referencesSet.has(serialisedReference);
  });

  return {
    type: 'ContentsByReferences',
    references: contentReferences,
  };
};

/**
 * Duplicated logic from the content-collections service, to not have to send another call.
 * @param collection
 */
export const getCollectionSharedSettingsId = (collection: ContentCollection | BasicCollection) => collection.id;
