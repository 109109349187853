import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { Snack } from '@/types';

const useGuiStore = defineStore('gui', () => {
  const snack = ref<Snack>({
    message: '',
    color: '',
    show: false,
  });
  const isBurgerMenuOpen = ref(false);
  const settings = ref<Record<string, string | boolean>>(JSON.parse(window.localStorage.getItem('gui') || '{}'));

  const toggleBurgerMenu = () => {
    isBurgerMenuOpen.value = !isBurgerMenuOpen.value;
  };
  const closeBurgerMenu = () => {
    isBurgerMenuOpen.value = false;
  };

  watch(
    settings,
    (newSettings) => {
      window.localStorage.setItem('gui', JSON.stringify(newSettings));
    },
    { deep: true },
  );

  return {
    snack,
    settings,
    isBurgerMenuOpen: computed(() => isBurgerMenuOpen.value),
    toggleBurgerMenu,
    closeBurgerMenu,
  };
});

export default useGuiStore;
