import psl, { ParsedDomain } from 'psl';
import { Capacitor } from '@capacitor/core';

export const UNKNOWN_REALM = 'unknown';
export const getBrowserRealm = (): string => {
  const host: string = window.location.hostname;
  if (host.endsWith('cloud')) {
    // External call in test
    const match = host.match(/(\w+)(?=\.dev.ilt.cloud)/);
    return match ? match[0] : UNKNOWN_REALM;
  }

  if (host.startsWith('localhost') && !Capacitor.isNativePlatform()) {
    // Internal local call
    return 'se';
  }

  const { tld } = psl.parse(host) as ParsedDomain;
  if (!tld) return UNKNOWN_REALM;

  switch (tld) {
    case 'co.uk':
      return 'uk';
    case 'com':
      return 'us';
    default:
      return tld;
  }
};
