import { library } from '@/api/library';

const initialBegreppaState = {
  isGuideSearching: false,
  theme: '',
  shortCode: '',
  guideFilters: {},
  guides: [],
};

export const begreppaStore = {
  state: initialBegreppaState,
  /**
   * ACTIONS
   */
  actions: {
    loadGuideFilters({ commit }) {
      library
        .loadGuideFilters()
        .then((response) => response.data)
        .then((filters) => {
          commit('SET_GUIDE_FILTERS', { filter: filters });
        });
    },
    setGuideSearching({ commit }, mode) {
      commit('SET_IS_GUIDE_SEARCHING', mode);
    },
  },
  /**
   * MUTATIONS
   */
  mutations: {
    SET_GUIDE_FILTERS(state, filters) {
      state.guideFilters = filters;
    },
    SET_IS_GUIDE_SEARCHING(state, mode) {
      state.isGuideSearching = mode;
    },
  },
  /**
   * GETTERS
   */
  getters: {
    libraryFilters: (state) => state.guideFilters,
    begreppaGuideFilters: (state) => {
      if (!state.guideFilters.filter) {
        return [];
      }
      // key added for local development reason
      const key = state.guideFilters.filter.service[2] !== undefined ? 2 : 1;
      return state.guideFilters.filter.service[key] && state.guideFilters.filter.service[key].children;
    },
    begreppaGuideLanguages: (state) => {
      if (!state.guideFilters.filter) {
        return [];
      }
      // key added for local development reason
      const key = state.guideFilters.filter.service[2] !== undefined ? 2 : 1;
      return state.guideFilters.filter.service[key] && state.guideFilters.filter.service[key].languages.children;
    },
  },
};
