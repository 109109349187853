import { computed, ref, watch } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import {
  CreateContentCollectionRequest,
  Cursor,
  ShareableContentCollection,
} from '@ilteducation/content-collections-types';
import useContentCollectionsStore from './content-collections';
import useActiveIdentity from './use-active-identity';
import { create as createApi, destroy as destroyApi, getOwnedCollections, update as updateApi } from './api';
import { CollectionPatch, ContentCollection, NewContentCollection } from './types';

const useMyCollectionsStore = defineStore('my-collections', () => {
  const collectionIds = ref<string[]>([]);
  const isLoading = ref(false);
  const cursor = ref<Cursor>();

  const hasMore = computed(() => cursor.value !== null);
  const collections = computed(() => {
    const { collectionById } = useContentCollectionsStore();
    return collectionIds.value.map((collectionId) => collectionById(collectionId) as ShareableContentCollection);
  });

  const activeIdentityStore = useActiveIdentity();
  const { identity } = storeToRefs(activeIdentityStore);

  watch(identity, () => {
    // reset on identity change
    collectionIds.value = [];
    cursor.value = undefined;
  });

  const load = (limit: number, context: string): void => {
    if (!hasMore.value || isLoading.value) {
      return;
    }

    isLoading.value = true;
    getOwnedCollections(context, identity.value.profileId, cursor.value as string | undefined, limit, undefined)
      .then(({ data, cursor: c }) => {
        const { put } = useContentCollectionsStore();
        put(data);

        // use Set to remove possible duplicates that can appear when first creating and then loading a new page
        collectionIds.value = [...new Set([...collectionIds.value, ...data.map((collection) => collection.id)])];
        cursor.value = c;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const create = (params: NewContentCollection): Promise<ContentCollection> => {
    isLoading.value = true;

    const updatedParams: CreateContentCollectionRequest = {
      ...params,
      owners: [identity.value],
      creator: identity.value,
    };

    return createApi(updatedParams)
      .then((collection) => {
        const { put } = useContentCollectionsStore();
        put([collection]);
        collectionIds.value = [...collectionIds.value, collection.id];
        return collection;
      })
      .finally(() => {
        isLoading.value = false; // TODO use separate flag?
      });
  };

  const update = (id: string, params: CollectionPatch): Promise<void> => {
    isLoading.value = true;

    return updateApi(id, params, identity.value.profileId)
      .then((collection) => {
        const { put } = useContentCollectionsStore();
        put([{ ...collection, id }]); // override id to ensure reserved id's are kept over the "real" which the api returns
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const remove = (collectionId: string): Promise<void> => {
    isLoading.value = true;

    return destroyApi(collectionId, identity.value.profileId)
      .then(() => {
        collectionIds.value = collectionIds.value.filter((id) => id !== collectionId);

        // TODO remove from content-collections store
        // TODO trigger removal from views?
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  return {
    collections,
    isLoading: computed(() => isLoading.value),
    hasMore,
    load,
    create,
    update,
    remove,
  };
});

export default useMyCollectionsStore;
