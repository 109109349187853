import { createVuetify, IconSet, ThemeDefinition } from 'vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import '@mdi/font/css/materialdesignicons.min.css';
import { mdi } from 'vuetify/iconsets/mdi';
import SVGIcon from '@/plugins/icons/SVGIcon.vue';
import ILTIcon from '@/plugins/icons/ILTIcon.vue';

export const light: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#1976d2',
    secondary: '#265eb8',
    mediumBlue: '#e0eff5',
    black: '#294053',
    neutralBlack: '#294053',
    background: '#f0f5f8',
    'primary-container': '#C0DAF2',

    /*
    Area guide color mappings

    tone5/main_text: darken5
    tone35/subjectcolor/headlines: darken3
    tone90/bg : lighten6
    tone50/highlight: darken2

     */

    /* Colors for the subjects */
    green: '#62b878',
    teal: '#5dc7bb',
    blue: '#56a4d7',
    gray: '#596a79',
    pink: '#be77a6',
    blush: '#d37777',
    yellow: '#cf9d58',
    purple: '#8169af',
    lightpink: '#946782',
    darkblue: '#124c8f',
    orange: '#ef7b47',
    gold: '#F3BF39',
    highlighterred: '#E85656',
    sybaritegreen: '#89CAAD',
    hkk: '#9ACD48',
  },
};

const custom: IconSet = {
  component: SVGIcon,
};
const ilt: IconSet = {
  component: ILTIcon,
};

const opts = {
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi,
      custom,
      ilt,
    },
  },
  theme: {
    defaultTheme: 'light',
    variations: {
      colors: [
        'background',
        'green',
        'teal',
        'blue',
        'gray',
        'pink',
        'blush',
        'yellow',
        'purple',
        'lightpink',
        'darkblue',
        'orange',
        'gold',
        'highlighterred',
        'sybaritegreen',
        'hkk',
        'black',
      ],
      lighten: 5,
      darken: 5,
    },
    themes: { light },
  },
};
const vuetify = createVuetify(opts);

export default vuetify;
