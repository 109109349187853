const realmToDomain = (realm: string): string => {
  switch (realm) {
    case 'se':
      return 'inlasningstjanst.se';
    case 'fr':
      return 'sondo.fr';
    case 'uk':
      return `ilteducation.co.uk`;
    case 'us':
      return 'ilteducation.com';
    default:
      return `ilteducation.${realm}`;
  }
};

export default realmToDomain;
