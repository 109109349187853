import axios, { AxiosError } from 'axios';
import axiosRetry, { exponentialDelay, isNetworkError } from 'axios-retry';
import { useConfigHelper } from '@ilteducation/config';
import * as datadog from '@ilteducation/datadog';

export type TokensByRefreshTokenParams = {
  clientId: string;
  refreshToken: string;
};

export type TokensByCodeParams = {
  clientId: string;
  redirectUrl: string;
  code: string;
  codeVerifier: string;
};

export type TokensByJwtBearerParams = {
  clientId: string;
  assertion: string;
  actAs: string;
};

export type UserInfoParams = {
  accessToken: string;
};

export type Tokens = {
  accessToken: string;
  idToken: string;
  refreshToken: string;
};

const { getUrl } = useConfigHelper();

const client = axios.create();

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      const ae: AxiosError = error as AxiosError;
      const context = {
        url: ae.response?.config.url,
        status: ae.response?.status,
        statusText: ae.response?.statusText,
        method: ae.response?.config.method,
      };
      datadog.report(error, context);
    }
    return Promise.reject(error);
  },
);

axiosRetry(client, {
  retries: 3,
  retryCondition: (err) =>
    isNetworkError(err) || Boolean(err.response && err.response.status >= 500 && err.response.status <= 599),
  retryDelay: exponentialDelay,
  onRetry: (attempt, err, cfg) => {
    console.warn(`Retrying request (${attempt}/${cfg['axios-retry']?.retries}). cause: ${err.message}`);
  },
});

const getTokens = (params: URLSearchParams): Promise<Tokens> =>
  client
    .post(`${getUrl('auth')}/v2/token`, params, {
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
    })
    .then(
      (response) =>
        ({
          accessToken: response.data.access_token as string,
          idToken: response.data.id_token as string,
          refreshToken: response.data.refresh_token as string,
        } as Tokens),
    );

export const tokensByRefreshToken = (params: TokensByRefreshTokenParams): Promise<Tokens> => {
  const refreshParams = new URLSearchParams();

  refreshParams.set('grant_type', 'refresh_token');
  refreshParams.set('refresh_token', params.refreshToken);
  refreshParams.set('client_id', params.clientId);

  return getTokens(refreshParams);
};

export const tokensByCode = (params: TokensByCodeParams): Promise<Tokens> => {
  const tokenParams = new URLSearchParams();

  tokenParams.set('grant_type', 'authorization_code');
  tokenParams.set('code', params.code);
  tokenParams.set('client_id', params.clientId);
  tokenParams.set('redirect_uri', params.redirectUrl);
  tokenParams.set('code_verifier', params.codeVerifier);

  return getTokens(tokenParams);
};

export const tokensByJwtBearer = (params: TokensByJwtBearerParams): Promise<Tokens> => {
  const tokenParams = new URLSearchParams();

  tokenParams.set('grant_type', 'urn:ietf:params:oauth:grant-type:jwt-bearer');
  tokenParams.set('assertion', params.assertion);
  tokenParams.set('client_id', params.clientId);
  tokenParams.set('act_as', params.actAs);

  return getTokens(tokenParams);
};

// TODO: Add proper return type
export const userInfo = (params: UserInfoParams) =>
  client
    .get(`${getUrl('auth')}/v1/userInfo`, { headers: { Authorization: `Bearer ${params.accessToken}` } })
    .then((response) => response.data);

export const checkValidAccessToken = (params: UserInfoParams) =>
  userInfo(params).catch((error: AxiosError) => {
    // ignore unexpected auth issues to avoid getting invalidly logged-out
    if (!error.response) {
      return;
    }
    if (error.response.status >= 499) {
      return;
    }

    throw error;
  });

export const logout = (path: string, params: unknown): Promise<void> =>
  client.get(`${getUrl('auth')}/${path}`, { params }).then(() => undefined);
