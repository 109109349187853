import { defineStore, storeToRefs } from 'pinia';
import CryptoJS from 'crypto-js';
import { useConfigHelper, isLocal } from '@ilteducation/config';
import { report } from '@ilteducation/datadog';
import { LoginParams } from './domain';
import { tokensByCode, tokensByJwtBearer } from './api';
import useTokensStore from './use-tokens';
import { loadAndClear, persist } from './storage';

const iltCloudUrl = 'http://ilt.cloud';

const STORAGE_KEY_CODE_VERIFIER = 'ilt-auth.code-verifier';
const { getAppUrl, getUrl } = useConfigHelper();

const createCodeChallenge = () => {
  const codeVerifier = CryptoJS.lib.WordArray.random(32)
    .toString(CryptoJS.enc.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

  const codeChallenge = CryptoJS.SHA256(codeVerifier)
    .toString(CryptoJS.enc.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

  return {
    codeVerifier,
    codeChallenge,
  };
};

const getRedirectUrl = (appName: string, domain?: string, basePath?: string): string => {
  const base = (basePath ?? '').replace(/^\/+/, '');
  if (isLocal()) {
    return `http://localhost:3000/${base}`;
  }

  const redirectUrl = getAppUrl(appName, domain);

  // If we on preview branch the redirect url should contain part of preview url
  return `${redirectUrl}/${base}`;
};

const useAuthentication = defineStore('ilt-auth-authentication', () => {
  const tokensStore = useTokensStore();

  const { tokens } = storeToRefs(tokensStore);

  const createLoginLink = ({
    appName,
    clientId,
    domain,
    reauthenticate,
    hint,
    redirectBasePath,
    localRedirectUrl,
    additionalQuery,
  }: LoginParams): string => {
    const { codeVerifier, codeChallenge } = createCodeChallenge();

    persist(STORAGE_KEY_CODE_VERIFIER, codeVerifier);

    const redirectUrl = localRedirectUrl ?? getRedirectUrl(appName, domain, redirectBasePath);

    const url = new URL(`${getUrl('auth')}/v1/authorize`);

    if (additionalQuery) {
      Object.entries(additionalQuery).forEach(([key, value]) => {
        if (typeof value === 'string') {
          url.searchParams.set(key, value);
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val) {
              url.searchParams.set(`${key}[]`, val);
            }
          });
        }
      });
    }

    url.searchParams.set('response_type', 'code');
    url.searchParams.set('scope', 'openid profile');
    url.searchParams.set('client_id', clientId);
    url.searchParams.set('redirect_uri', redirectUrl);
    url.searchParams.set('code_challenge', codeChallenge);
    url.searchParams.set('code_challenge_method', 'S256');

    if (hint) {
      url.searchParams.set('amr_hint', ['pwd', 'sms'].includes(hint) ? hint : `${iltCloudUrl}/auth-method/${hint}`);
    }

    if (reauthenticate) {
      url.searchParams.set('reauthenticate', reauthenticate);
    }

    return url.toString();
  };

  const createEscalationLink = (params: LoginParams): string =>
    createLoginLink({
      ...params,
      reauthenticate: undefined,
      additionalQuery: {
        ...params.additionalQuery,
        acr_values: `${iltCloudUrl}/assurance/secure`,
        id_token_hint: tokens.value?.idToken,
      },
    });

  const authenticateWithCode = async (
    appName: string,
    code: string,
    clientId: string,
    basePath?: string,
    domain?: string,
    localRedirectUrl?: string,
  ): Promise<void> => {
    const codeVerifier = await loadAndClear<string>(STORAGE_KEY_CODE_VERIFIER);
    const redirectUrl = localRedirectUrl ?? getRedirectUrl(appName, domain, basePath);

    if (!codeVerifier) {
      report(`Missing code verifier when authenticating with code ${code}.`);
      console.log('Missing code verifier when authenticating with code.');
      return Promise.reject(new Error('Missing code verifier when authenticating with code.'));
    }

    return tokensByCode({
      code,
      codeVerifier,
      redirectUrl,
      clientId,
    })
      .then(tokensStore.acceptTokens)
      .catch((e) => {
        console.debug('Failed to get token by code', e);
        report(e);
        throw e;
      });
  };

  const createActAsLink = (params: LoginParams & { actAs: string }): string =>
    createLoginLink({
      ...params,
      reauthenticate: 'auto',
      hint: 'google',
      additionalQuery: {
        ...params.additionalQuery,
        act_as: params.actAs,
      },
    });

  const createLogoutLink = (redirectUrl: string) => {
    const url = new URL(`${getUrl('auth')}/logout`);
    url.searchParams.set('redirect', redirectUrl);

    return url.toString();
  };

  return {
    createLoginLink,
    createEscalationLink,
    createActAsLink,
    authenticateWithCode,
    createLogoutLink,
  };
});

export default useAuthentication;
