import { datadogRum } from '@datadog/browser-rum';

export type DDSetupOptions = {
  applicationId?: string;
  clientToken?: string;
  environment: string;
  service: string;
  sampleRate?: number;
  version?: string;
};

const setup = ({
  applicationId,
  clientToken,
  environment,
  service,
  sampleRate = 10,
  version = '0',
}: DDSetupOptions) => {
  if (applicationId && clientToken) {
    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.eu',
      service,
      env: environment,
      version,
      sampleRate,
      sessionReplaySampleRate: 0, // 0 for no session recordings
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    // Needed for Browser RUM data collecting also.
    datadogRum.startSessionReplayRecording();
    console.debug('Datadog started!');
  }
};

export default setup;
