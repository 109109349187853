import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

export type Identity = {
  userId: string;
  profileId?: string;
};

const useActiveIdentity = defineStore('active-identity', () => {
  const identity = ref<Identity>();

  const setActive = (newActive: Identity) => {
    identity.value = newActive;
  };

  return {
    identity: computed(() => identity.value!),
    setActive,
  };
});

export default useActiveIdentity;
