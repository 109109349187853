import { configHelper as ch } from '@ilteducation/config';
import configHelper from './config-helper';

const countryCode = ch.getCountryCode();
const serviceName = configHelper.getServiceName();
const fullServiceName = configHelper.getFullServiceName();
const nodeEnv = process.env.NODE_ENV;
const toastClassName = configHelper.getToastClass(serviceName);
const basedLinks = configHelper.getBasedLinks();

export default class ConfigProvider {
  static get CONFIG() {
    return {
      nodeEnv,
      environment: 'VITE_ENVIRONMENT',
      serviceName,
      fullServiceName,
      countryCode,
      portalBaseUrl: configHelper.getPortalUrl(),
      logoImageFile: `${serviceName}-logo.svg`,
      logoImageBWFile: `${serviceName}-logo-bw.svg`,
      toastClassName,
      authUrl: `VITE_AUTH_URL`,
      controlPanelUrl: 'VITE_CONTROL_PANEL_API_URL',
      apiUrl: `VITE_INTRO_FILMS_API_URL`,
      apiLibraryUrl: `VITE_LIBRARY_API_URL`,
      authClientId: `VITE_MANABUA_AUTH_CLIENT_ID`,
      authRedirectUrl: `VITE_MANABUA_AUTH_REDIRECT_URI`,
      iltCloudUrl: 'http://ilt.cloud',
      exerciseUrl: `VITE_MANABUA_EXERCISE_URL`,
      submissionUrl: `VITE_MANABUA_SUBMISSION_URL`,
      audioCentralUrl: `VITE_AUDIO_CENTRAL_API_URL`,
      historyTrackerApiUrl: `VITE_HISTORY_TRACKER_URL`,
      contentRegistryUrl: `VITE_CONTENT_REGISTRY_API_URL`,
      exerciseTrackerUrl: `VITE_EXERCISE_TRACKER_URL`,
      curriculumUrl: `VITE_CURRICULUM_URL`,
      feedbackFormUrl: `VITE_MANABUA_FEEDBACK_URL`,
      teacherGuideUrl: `VITE_TEACHER_GUIDE_URL`,
      guidesLokaliseProject: 'VITE_GUIDES_LOKALISE_PROJECT',
      keywordsLokaliseProject: `VITE_KEYWORDS_LOKALISE_PROJECT`,
      babelfishUrl: `VITE_BABELFISH_URL`,
      cdnDomain: `VITE_CDN_DOMAIN`,
      showGuides: countryCode === 'se',
      showTeacherGuide: countryCode === 'se',
      showCurriculum: true,
      // links
      supportUrl: `VITE_SUPPORT_PAGE_URL`,
      settingsUrl: basedLinks.settings,
      profileUrl: basedLinks.profile,
      homeUrl: basedLinks.home,
      latestNewsUrl: basedLinks.latestNews,
      mainDomain: basedLinks.mainDomain,
      basePath: import.meta.env.BASE_URL || '/',
    };
  }

  static value(name) {
    const value = name.split('.').reduce((obj, key) => {
      if (!(key in obj)) {
        // eslint-disable-next-line no-console
        console.error(`Configuration: There is no key named "${name}"`);
        return {};
      }
      return obj[key];
    }, this.CONFIG);

    if (!value) {
      // eslint-disable-next-line no-console
      console.debug(`Configuration: Value for "${name}" is not defined `);
      return;
    }

    if (typeof value === 'string' && value.startsWith('VITE_')) {
      const envName = process.env[value] ? value : `${value}_${countryCode.toUpperCase()}`;
      const envValue = process.env[envName];

      if (envValue) {
        // eslint-disable-next-line consistent-return
        return envValue;
      }

      // eslint-disable-next-line no-console
      console.debug(`Configuration: Environment variable "${envName}" is not defined`);
    }
    // eslint-disable-next-line consistent-return
    return value;
  }
}
