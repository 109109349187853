import { Network } from '@capacitor/network';
import { PluginListenerHandle } from '@capacitor/core';

export const checkIsOnline = (): Promise<boolean> => Network.getStatus().then((status) => status.connected);
export const createOnlineListener = async (callback: () => void): Promise<PluginListenerHandle> => {
  let networkStatusListenerHandle: PluginListenerHandle | undefined = await Network.addListener(
    'networkStatusChange',
    (status) => {
      if (status.connected && networkStatusListenerHandle !== undefined) {
        networkStatusListenerHandle.remove();
        networkStatusListenerHandle = undefined; // ensure refreshTokens is only called once

        callback();
      }
    },
  );

  return networkStatusListenerHandle;
};
