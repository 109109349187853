import { computed, ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { useUserContext } from '@ilteducation/ilt-auth';
import type { GetSchoolUnitRelationsRequest } from '@ilteducation/customer-mgmt-types';
import { getOrganisations, GetSchoolUnitRelationsResponse, SchoolUnitRelation } from './api';

const userOrganisationsStore = defineStore(
  'user-organisations',
  () => {
    const { user } = storeToRefs(useUserContext());

    const organisations = ref<SchoolUnitRelation[]>();
    const isLoading = ref(false);
    const nextPage = ref<number | null>(1);

    const hasMore = computed(() => nextPage.value !== null);

    const load = async (pageSize: number) => {
      if (!hasMore.value || isLoading.value) {
        return;
      }

      isLoading.value = true;

      const params: GetSchoolUnitRelationsRequest = {
        pageNumber: nextPage.value!,
        pageSize,
        filters: {
          users: [user.value.uid],
        },
      };

      let response: GetSchoolUnitRelationsResponse;
      try {
        response = await getOrganisations(params);
      } catch (e) {
        isLoading.value = false;
        console.debug('Failed to retrieve organisations.', e);
        return;
      }

      if (response.data.length < pageSize) {
        nextPage.value = null; // assume end reached
      } else {
        nextPage.value = 1 + nextPage.value!;
      }

      organisations.value = [...(organisations.value ?? []), ...response.data];

      isLoading.value = false;
    };

    return {
      organisations,
      isLoading,
      hasMore,
      load,
    };
  },
  {
    // @ts-ignore
    persist: true, // to enable persistence where needed by eg. native apps
  },
);

export default userOrganisationsStore;
