import axios from 'axios';
import { libraryUrl } from '@/foundations/helpers/router';

export const library = {
  loadGuideFilters() {
    return new Promise((resolve, reject) => {
      axios
        .get(libraryUrl(`v1/filter`))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getBookEpubUrl(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(libraryUrl(`v1/books/${id}/play`))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  putBookInShelf(bookId, userId) {
    return new Promise((resolve, reject) => {
      axios
        .put(libraryUrl(`v1/user/${userId}/books`), {
          bookId,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteBookFromShelf(bookId, userId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(libraryUrl(`v1/user/${userId}/books/${bookId}`))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getBooks(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(libraryUrl(`v1/books`), { params })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getBook(bookId) {
    return new Promise((resolve, reject) => {
      axios
        .get(libraryUrl(`v1/books/${bookId}`))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUserBooks(userId, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(libraryUrl(`v1/user/${userId}/books`), { params })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
