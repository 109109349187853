import axios from 'axios';

const lokaliseUrl = `https://translations${process.env.ENVIRONMENT !== 'production' ? '-dev' : ''}.ilteducation.com`;

const axiosInstance = axios.create(); // needed to avoid e.g. auth interceptors that messes up CORS

export default async (appName: string, language: string) =>
  axiosInstance
    .get(`${lokaliseUrl}/${appName}/web/${language}.json`) // TODO cache and use "If-Modified-Since" from cloudfront to check for updated
    .then((response) => response.data)
    .catch((error) => console.error('Failed to load language.', error));
