import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useBooks } from '@ilteducation/books';
import type { Content } from '@ilteducation/content-registry-types';
import { ContentQuery } from './types';
import { getCollectionContent } from './api';
import useActiveIdentity from './use-active-identity';

type ContentsState = {
  isLoading: boolean;
  contentQuery: ContentQuery;
  contents: Content[];
};

type State = {
  [collectionId: string]: ContentsState | undefined;
};

const useCollectionContentsStore = defineStore('content-collection-contents', () => {
  const contentsState = ref<State>({});

  const contents = computed(() => (collectionId: string) => contentsState.value[collectionId]?.contents ?? []);
  const isLoading = computed(() => (collectionId: string) => contentsState.value[collectionId]?.isLoading ?? false);
  const hasMore = computed(
    () => (collectionId: string) => contentsState.value[collectionId]?.contentQuery.cursor !== null,
  );

  const activeIdentityStore = useActiveIdentity();
  const { identity } = storeToRefs(activeIdentityStore);

  watch(identity, () => {
    // reset on identity change
    contentsState.value = {};
  });

  const loadCollectionContents = (collectionId: string, contentQuery: ContentQuery): void => {
    const state = contentsState.value[collectionId];
    if (state?.isLoading === true) {
      return;
    }

    contentsState.value = {
      ...contentsState.value,
      [collectionId]: {
        isLoading: true,
        contentQuery,
        contents: state?.contents ?? [],
      },
    };

    getCollectionContent(collectionId, contentQuery, identity.value.profileId)
      .then(({ cursor, data }) => {
        contentsState.value = {
          ...contentsState.value,
          ...{
            [collectionId]: {
              contentQuery: {
                ...contentsState.value[collectionId]?.contentQuery,
                cursor,
              },
              isLoading: false,
              contents: [...(state?.contents ?? []), ...data],
            },
          },
        };

        const { addBooks } = useBooks();
        addBooks(data as Content[]); // TODO types need to be harmonised...
      })
      .catch((error) => {
        contentsState.value = {
          ...contentsState.value,
          [collectionId]: {
            contentQuery: {
              ...contentsState.value[collectionId]?.contentQuery,
              cursor: null, // TODO future retries?
            },
            isLoading: false,
            contents: state?.contents ?? [],
          },
        };

        throw error;
      });
  };

  const next = (collectionId: string): void => {
    const state = contentsState.value[collectionId];

    if (!state) {
      console.error(`Can't paginate collection ${collectionId} when it hasn't been loaded.`);
      return;
    }

    loadCollectionContents(collectionId, state.contentQuery);
  };

  const reset = (collectionId: string): void => {
    contentsState.value = {
      ...contentsState.value,
      ...{
        [collectionId]: undefined,
      },
    };
  };

  return {
    contents,
    isLoading,
    hasMore,
    loadCollectionContents,
    next,
    reset,
  };
});

export default useCollectionContentsStore;
