import useGuiStore from '@/store/gui';
import { storeToRefs } from 'pinia';

export default () => {
  const guiStore = useGuiStore();
  const { snack } = storeToRefs(guiStore);

  const snackbarSuccess = (message: string) => {
    snack.value = {
      message,
      color: 'success',
      show: true,
    };
  };
  const snackbarError = (message: string) => {
    snack.value = {
      message,
      color: 'error',
      show: true,
    };
  };

  return {
    snackbarSuccess,
    snackbarError,
    snack,
  };
};
