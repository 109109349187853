import { useConfigHelper, realmLocaleMapper } from '@ilteducation/config';

const codeToLanguageTag: Record<string, string> = {
  sv: 'sv-SE',
  gb: 'en-GB',
  us: 'en-US',
  de: 'de-DE',
  fi: 'fi-FI',
  fr: 'fr-FR',
  nb: 'nb-NO',
  nn: 'nn-NO',
  nl: 'nl-NL',
};

export const getBrowserLanguage = (): string | undefined => {
  const { countryCode } = useConfigHelper();
  const lang = realmLocaleMapper[countryCode.toLowerCase()];

  if (!lang) {
    return undefined;
  }

  const languageTag = codeToLanguageTag[lang];

  if (languageTag) {
    return languageTag;
  }

  return lang;
};
