import { computed, Ref, ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { until } from '@vueuse/core';
import { BasicCollection } from '@ilteducation/content-collections-types';
import { AxiosError, AxiosResponse } from 'axios';
import { getContentCollection } from './api';
import { ContentCollection } from './types';
import useCollectionContentsStore from './collection-contents';
import { isFullProjection } from './utils';
import useActiveIdentity from './use-active-identity';

const useContentCollectionsStore = defineStore('content-collections', () => {
  const collections = ref<Record<string, ContentCollection | BasicCollection | undefined | null>>({});
  const isLoadingFlags = ref<Record<string, boolean | undefined>>({});
  const errors = ref<Record<string, AxiosResponse>>({});

  const setLoadingById = (collectionId: string, isLoading: boolean) => {
    isLoadingFlags.value = {
      ...isLoadingFlags.value,
      [collectionId]: isLoading,
    };
  };

  const setErrorsById = (collectionId: string, err: AxiosResponse) => {
    errors.value = {
      ...errors.value,
      [collectionId]: err,
    };
  };

  const initActiveIdentity = async () => {
    const activeIdentityStore = useActiveIdentity();
    const { identity } = storeToRefs(activeIdentityStore);
    await until(identity).not.toBe(undefined, { timeout: 20000, throwOnTimeout: true });
    return identity;
  };

  const put = (newCollections: (ContentCollection | BasicCollection)[]) => {
    newCollections.forEach((newCollection) => {
      const existingCollection = collections.value[newCollection.id];
      if (existingCollection && isFullProjection(existingCollection) && !isFullProjection(newCollection)) {
        // do not overwrite, in case of "full" model already being in store but the new is only a basic projection
        return;
      }
      if (existingCollection && isFullProjection(existingCollection) && isFullProjection(newCollection)) {
        // assume contents was update, trigger reload
        const { reset } = useCollectionContentsStore();
        reset(newCollection.id); // TODO instead of this hack, we should perhaps retrieve the content object and add to collection-contents?
      }

      collections.value = {
        ...collections.value,
        [newCollection.id]: newCollection,
      };
    });
  };

  const load = async (collectionId: string) => {
    setLoadingById(collectionId, true);
    const identity = await initActiveIdentity();
    getContentCollection(collectionId, identity.value.profileId)
      .then((collection) => {
        collections.value = {
          ...collections.value,
          [collectionId]: collection ? { ...collection, id: collectionId } : collection, // keep the originally reference id, in case it's a reserved one
        };
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          setErrorsById(collectionId, err.response);
        }
      })
      .finally(() => setLoadingById(collectionId, false));
  };

  const isLoadingById = computed(() => (collectionId: string) => isLoadingFlags.value[collectionId] ?? false);
  const collectionById = computed(() => (collectionId: string) => collections.value[collectionId]);
  const errorById = computed(() => (collectionId: string) => errors.value[collectionId]);

  return {
    collections: computed(() => collections),
    isLoadingById,
    setLoadingById,
    collectionById,
    errorById,
    put,
    load,
    initActiveIdentity,
  };
});

export default useContentCollectionsStore;
