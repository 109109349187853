import { Preferences } from '@capacitor/preferences';

export type RealmState = {
  selectedRealm: string;
};

const realmStateKey = 'ilt-config.realm';
export const getRealmState = async (): Promise<RealmState | undefined> => {
  const { value: state } = await Preferences.get({ key: realmStateKey });

  if (!state) {
    return undefined;
  }

  return JSON.parse(state);
};

export const persistSelectedRealm = async (selectedRealm: string) => {
  const state = (await getRealmState()) ?? {};
  const newState: RealmState = {
    ...state,
    selectedRealm,
  };

  await Preferences.set({ key: realmStateKey, value: JSON.stringify(newState) });
};
