import './auth-fix'; // TODO remove after some time :-)
import { createApp } from 'vue';
import { setup as DataDogSetup } from '@ilteducation/datadog';
import posthogPlugin from '@ilteducation/posthog';
import type { PluginOptions } from '@ilteducation/posthog';
import pinia from '@/plugins/pinia';
import { i18n } from '@/plugins/translation';
import stonlyPlugin from '@ilteducation/stonly';
import store from './store';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import interceptorsSetup from './foundations/helpers/interceptors';

interceptorsSetup();

DataDogSetup({
  applicationId: process.env.VITE_DD_APPLICATION_ID,
  clientToken: process.env.VITE_DD_CLIENT_TOKEN,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  environment: process.env.VITE_ENVIRONMENT!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  service: process.env.VITE_DD_SERVICE!,
  sampleRate: process.env.VITE_ENVIRONMENT !== 'production' ? 100 : 10,
  version: store.getters.appVersion,
});

const app = createApp(App);

app.use(vuetify);
app.use(router);
app.use(pinia);
app.use(store);
app.use(i18n);

if (import.meta.env.VITE_ENVIRONMENT === 'production' || import.meta.env.VITE_ENVIRONMENT === 'staging') {
  // only use in prod/staging
  app.use(stonlyPlugin, { wid: import.meta.env.VITE_STONLY_WID });
}
if (import.meta.env.VITE_ENVIRONMENT === 'production') {
  const apiKey: string = import.meta.env.VITE_POSTHOG_API_KEY;
  app.use(posthogPlugin, { apiKey, router } as PluginOptions);
}
app.config.globalProperties.$store = store;

app.mount('#app');
