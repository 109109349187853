import { Capacitor } from '@capacitor/core';
import realmToShard from './realm-shard-mapper';
import realmToDomain from './realm-domain-mapper';
import realmTopDomain from './realm-top-domain-mapper';
import { persistSelectedRealm } from './store';
import { getBrowserRealm } from './browser-realm';

export const defaultRealms = ['de', 'fi', 'fr', 'no', 'nl', 'se', 'uk', 'us'];
let overrideEnv: string;
export const host = (): string => window.location.hostname;
export const environment = () => {
  if (overrideEnv) {
    return overrideEnv;
  }
  if (process.env.ENVIRONMENT) {
    return process.env.ENVIRONMENT;
  }
  if (process.env.VUE_APP_ENVIRONMENT) {
    return process.env.VUE_APP_ENVIRONMENT;
  }
  return process.env.VITE_ENVIRONMENT;
};

export const isNative = (): boolean => Capacitor.isNativePlatform();
export const isLocal = (): boolean => environment() === 'dev' || (host().startsWith('localhost') && !isNative());
export const isStaging = (): boolean => environment() === 'staging';
export const isProd = (): boolean => environment() === 'production';

let realm: string = getBrowserRealm();

const useConfigHelper = () => {
  const changeCountryCode = async (newCode: string): Promise<void> => {
    if (realm === newCode) {
      console.debug(`Realm ${newCode} is already initialised`);
      return;
    }

    realm = newCode;
    await persistSelectedRealm(newCode)
      .then(() => {
        console.debug(`Realm changed to ${newCode}`);
      })
      .catch(() => {
        throw new Error(`Realm ${newCode} is not initialised`);
      });
  };

  const getShard = (): string => realmToShard(realm);

  const getUrl = (serviceName: string, withoutProtocol?: boolean, predefinedDomain?: string): string => {
    const proto = withoutProtocol ? '' : 'https://';
    let staging = isStaging();

    if (isLocal() && environment() !== 'production') {
      staging = true;
    }

    let domain = predefinedDomain;
    if (!predefinedDomain) {
      domain = realmToDomain(realm);
    }
    const subdomainSuffix = staging ? '-dev' : '';

    return `${proto}${serviceName}${subdomainSuffix}.${domain}`;
  };

  const getAppUrl = (appName: string, domain?: string): string => {
    if (domain) {
      switch (environment()) {
        case 'staging':
          return `https://${appName}-dev.${domain}`;
        default:
          return `https://${appName}.${domain}`;
      }
    }

    const tld = realmTopDomain[realm];

    let subDomain = 'app';
    let sld = appName;
    if (realm === 'fr' && appName === 'ilteducation') {
      subDomain = 'manage';
      sld = 'sondo';
    }

    switch (environment()) {
      case 'dev':
      case 'staging':
        return `https://${subDomain}-dev.${sld}.${tld}`;
      default:
        return `https://${subDomain}.${sld}.${tld}`;
    }
  };

  const getApiUrl = (apiName: string, isGlobal?: boolean): string => {
    const shard = isGlobal ? 'global' : realmToShard(realm);

    switch (environment()) {
      case 'devlocal': // TODO remove legacy environment
      case 'dev':
        return `https://api.${shard}.staging.ilteducation.com/${apiName}`;
      case 'staging':
        return `https://api.${shard}.staging.ilteducation.com/${apiName}`;
      default:
        return `https://api.${shard}.prod.ilteducation.com/${apiName}`;
    }
  };

  const setEnvironment = (env: string) => {
    overrideEnv = env;
  };

  return {
    realm,
    countryCode: realm,
    changeCountryCode,
    getShard,
    getUrl,
    getAppUrl,
    getApiUrl,
    setEnvironment,
  };
};

export default useConfigHelper;
