<script setup lang="ts">
import { ref, computed } from 'vue';
import config from '@/config/ConfigProvider';
import { useLocale, useLocalisation } from '@/plugins/translation';
import useGuiStore from '@/store/gui';

type Props = {
  searchLabel?: string;
};

defineProps<Props>();

const { t, l } = useLocale();
const { language } = useLocalisation();
const serviceName = ref<string>(config.value('serviceName'));
const logoImageFile = ref<string>(config.value('logoImageFile'));
const showGuides = ref<string>(config.value('showGuides'));
const countryCode = ref<string>(config.value('countryCode'));
const { toggleBurgerMenu, closeBurgerMenu } = useGuiStore();

const capitalizationClass = computed(() => (language.value === 'en-GB' ? 'text-capitalize' : 'text-capitalize-first'));
const logoImageUrl = computed(() => new URL(`/src/assets/images/${logoImageFile.value}`, import.meta.url).href);
</script>

<template>
  <v-app-bar color="white">
    <v-row no-gutters style="max-width: none">
      <v-col cols="12" md="3" lg="2" class="d-flex align-center">
        <div class="d-flex align-center justify-start">
          <v-app-bar-nav-icon class="nav-button" @click="toggleBurgerMenu" @keydown.esc="closeBurgerMenu" />
          <v-app-bar-title class="toolbar-title pl-1 pl-sm-2">
            <router-link :to="{ name: 'begreppaLanding' }">
              <v-img :src="logoImageUrl" height="100%" max-height="30" width="140" cover :alt="serviceName + ' Logo'" />
            </router-link>
          </v-app-bar-title>
        </div>
      </v-col>
      <v-col cols="7" lg="8">
        <v-tabs v-if="countryCode === 'se'" align-tabs="center" height="48px" class="d-none d-md-block text-secondary">
          <v-tab :to="{ name: 'Videos' }">
            <v-icon start icon="custom:videoIcon"></v-icon>
            <div :class="capitalizationClass">
              {{ t(l.tabs.films) }}
            </div>
          </v-tab>
          <v-tab :to="{ name: 'Library' }">
            <v-icon start icon="custom:libraryIcon"></v-icon>
            <div :class="capitalizationClass">
              {{ t(l.tabs.library) }}
            </div>
          </v-tab>
          <v-tab v-if="showGuides" :to="{ name: 'Guides' }">
            <v-icon start icon="custom:guideIcon"></v-icon>
            <div :class="capitalizationClass">
              {{ t(l.tabs.guides) }}
            </div>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<style lang="scss" scoped>
@use '@/foundations/theme/settings';

.v-app-bar {
  :deep(.v-toolbar__content) {
    padding: 4px 16px;
  }
}

.nav-button {
  color: var(--v-blue-grey-darken-2) !important;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;

  .v-icon {
    transition: none;
  }

  &:hover {
    color: white !important;
    background: rgb(var(--v-theme-secondary));
  }

  &:focus {
    outline: none !important;
  }
}

.text-capitalize-first {
  text-transform: lowercase;
}

.text-capitalize-first::first-letter {
  text-transform: uppercase;
}
</style>
