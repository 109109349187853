import { I18n, createI18n } from 'vue-i18n';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import createUseLocale, { UseLocale } from './use-locale';
import createUseLocalisation, { UseLocalisation } from './use-localisation';
import { getLanguageState } from './store';
import { LanguageDefinition } from './types';
import { getBrowserLanguage } from './browser-language';

export type I18nSetupOptions<L> = {
  appName: string;
  defaultLanguageName: string;
  defaultLanguage: L;
  supportedLanguages: LanguageDefinition[];
};

export type I18nSetup<L> = {
  i18n: I18n<any, any, any, string, false>;
  useLocalisation: UseLocalisation;
  useLocale: UseLocale<L>;
};

const setup = <L>({
  appName,
  defaultLanguageName,
  defaultLanguage,
  supportedLanguages,
}: I18nSetupOptions<L>): I18nSetup<L> => {
  const i18n = createI18n({
    legacy: false,
    locale: defaultLanguageName,
    fallbackLocale: defaultLanguageName,
    messages: { dev: defaultLanguage as any },
  });

  const useLocalisation = createUseLocalisation(appName, i18n, supportedLanguages);
  const useLocale = createUseLocale<L>(defaultLanguage);

  const { isLanguageSupported, changeLanguage } = useLocalisation();

  (async () => {
    let languageIso;
    if (Capacitor.isNativePlatform()) {
      languageIso = await Device.getLanguageCode().then(
        (code) => supportedLanguages.find((language) => language.short === code.value)?.iso,
      );
    } else {
      languageIso = getBrowserLanguage();
    }

    const language = [getLanguageState()?.selectedLanguage, languageIso]
      .filter((l) => l && isLanguageSupported(l))
      .find((l) => l);
    changeLanguage(language ?? defaultLanguageName);
  })();

  return {
    i18n,
    useLocalisation,
    useLocale,
  };
};

export default setup;
